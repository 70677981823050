.editor-formao-modules h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 3em;
  font-weight: 700;
  color: #454560;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .editor-formao-modules h2 {
    font-size: 2.2em;
  }
}

.editor-formao-modules h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 2em;
  font-weight: 600;
  color: #315397;
  margin-bottom: 20px;
}

.editor-formao-modules h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  color: #315397;
  margin-bottom: 20px;
}

.editor-formao-modules p {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #454560;
  margin-bottom: 20px;
}

.editor-formao-modules a {
  color: #315397;
  text-decoration: none;
  position: relative;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

.editor-formao-modules a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #315397;
  height: 2px;
  -webkit-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
}

.editor-formao-modules a:hover {
  color: #E73333;
}

.editor-formao-modules a:hover::after {
  background-color: #E73333;
}

.editor-formao-modules ul, .editor-formao-modules ol {
  padding-left: 20px;
  margin-bottom: 20px;
}

.editor-formao-modules ul {
  list-style: none;
}

.editor-formao-modules ul li ul {
  margin: 0;
}

.editor-formao-modules ol {
  list-style: none;
  counter-reset: item;
}

.editor-formao-modules li p {
  display: inline;
  margin-bottom: 0;
}

.editor-formao-modules ol li {
  counter-increment: li;
  display: block;

  
}

.editor-formao-modules ol li ol {
  margin: 0;
}

.editor-formao-modules li::before {
  font-family: 'Nunito', sans-serif;
  color: #315397;
  font-weight: 600;
  width: 1.5em;
  margin-left: -1em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
}

.editor-formao-modules ol li::before {
  content: counters(item, '.') '. ';
  counter-increment: item;
  font-size: 16px;
}

.editor-formao-modules div[data-vimeo-video] iframe {
  display: block;
  margin: 0 auto 20px auto;
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 800px;
  height: auto;
  background-color: #000;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.editor-formao-modules div[data-youtube-video] iframe {
  display: block;
  margin: 0 auto 20px auto;
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 800px;
  height: auto;
  background-color: #000;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.editor-formao-modules figure {
  margin: 0 auto 20px auto;
  position: relative;
}

.editor-formao-modules figure[data-video] {
  width: 100%;
  max-width: 800px;
}

.editor-formao-modules figure[data-video] iframe {
  display: block;
  margin: 0 auto 20px auto;
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  background-color: #000;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.editor-formao-modules figure img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.editor-formao-modules figure[data-video] figcaption {
  margin-top: 0;
}

/* .editor-formao-modules figure:hover::before {
  display: block;
  position: absolute;
  content: 'Supprimer';
  top: -20px;
  left: 0;
  right: 0;
  height: 20px;
  background-color: rgba(0,0,0,.5);
  z-index: 5;
} */

.editor-formao-modules figure figcaption {
  text-align: center;
  font-style: italic;
  color: #6a6a94;
  font-weight: 400;
  font-size: 16px;
}

.editor-formao-modules mark {
  padding: 0.05em 0.2em;
  color: #454560;
  border-radius: 2px;
}

.editor-formao-modules blockquote {
  border-left: 2px solid #454560;
  padding-left: 20px;
  margin-bottom: 20px;
}

.editor-formao-modules blockquote p {
  font-size: 16px;
  display: inline-block;
  color: #454560;
  font-weight: 300;
  font-style: italic;
}

.editor-formao-modules blockquote p:before {
  content: '«' "\00a0";
  display: inline-block;
  vertical-align: bottom;
  position: relative;
}

.editor-formao-modules blockquote p:after {
  content: "\00a0" "»";
  display: inline-block;
  vertical-align: bottom;
  position: relative;
}

.editor-formao-modules blockquote > :last-child {
  margin-bottom: 0 !important;
}

.editor-formao-modules div.important {
  margin: 40px;
	padding: 24px;
	border: 2px solid #315397;
	background-color: #D7D9EB;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	border-top-left-radius: 16px;
}

.editor-formao-modules div.important::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='utf-8'?%3e%3c!-- Generator: Adobe Illustrator 27.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e%3csvg version='1.1' id='Calque_1' height='32px' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 58.7 59.4' style='enable-background:new 0 0 58.7 59.4;' xml:space='preserve'%3e%3cstyle type='text/css'%3e .st0%7bopacity:0.5;fill:%23305296;%7d .st1%7bfill:%23305296;%7d %3c/style%3e%3cg%3e%3ccircle class='st0' cx='31.2' cy='31.9' r='27.5'/%3e%3cpath class='st1' d='M27.5,0C12.3,0,0,12.3,0,27.5C0,42.7,12.3,55,27.5,55S55,42.7,55,27.5C55,12.3,42.7,0,27.5,0z M27.5,51 C14.5,51,4,40.5,4,27.5S14.5,4,27.5,4S51,14.5,51,27.5S40.5,51,27.5,51z'/%3e%3cg%3e%3ccircle class='st1' cx='27.5' cy='13.9' r='3.7'/%3e%3cpath class='st1' d='M27.5,44.9L27.5,44.9c1.4,0,2.6-1.2,2.6-2.6V23.4c0-1.4-1.2-2.6-2.6-2.6h0c-1.4,0-2.6,1.2-2.6,2.6v18.8 C24.9,43.7,26.1,44.9,27.5,44.9z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e ") '\00a0 \00a0 À retenir';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  color: #315397;
}

.editor-formao-modules div.important li::before {
  color: #e73333;
}

.editor-formao-modules div.important > :last-child {
  margin-bottom: 0 !important;
}

.editor-formao-modules div.important *:not(pre, pre *, code, code *), .editor-formao-modules div.important li::before  {
  color: #315397 !important;
}
