.create-statistics .bordered {
  border: 1px solid #f0f0f0;
  border-top: none;
}

.create-statistics .bordered:first-of-type {
  border-top: 1px solid #f0f0f0;
}

.create-statistics .bordered .ant-col {
  border-left: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;
  flex: 1;
}

.create-statistics .bordered .ant-col:first-of-type {
  border-left: none;
}

.create-statistics .bordered .ant-col.header {
  background-color: #fafafa;
}

.header-main {
  font-weight: bold;
}
