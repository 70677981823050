#formao h2 {
  font-family: 'Poppins', sans-serif !important;
  font-size: 3em;
  font-weight: 700;
  color: #454560;
  margin-bottom: 20px;
}

@media (min-width: 600px) {
  #formao h2 {
    font-size: 3em;
  }
}

#formao h3 {
  font-family: 'Poppins', sans-serif !important;
  font-size: 2em;
  font-weight: 600;
  color: #315397;
  margin-bottom: 20px;
}

#formao h4 {
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.5em;
  font-weight: 500;
  color: #315397;
  margin-bottom: 20px;
}

#formao p {
  font-family: 'Nunito', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: #454560;
  margin-bottom: 20px;
}

#formao a {
  color: #315397;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

#formao a::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #315397;
  height: 2px;
  -webkit-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
}

#formao a:hover {
  color: #e73333;
}

#formao a:hover::after {
  background-color: #e73333;
}

#formao ul {
  padding-left: 20px;
  margin-bottom: 20px;
  list-style: none;
  list-style-image: url('./assets/images/Formao_puce1.svg');
}

#formao ul li {
  font-size: 16px;
}

#formao ul li p {
  display: inline;
  margin-bottom: 0;
}

#formao ul li ul {
  margin: 0;
  list-style-image: url('./assets/images/Formao_puce2.svg');
}

#formao ul li ul ul {
  list-style-image: url('./assets/images/Formao_puce3.svg');
}

#formao ul li ul ul ul {
  list-style-image: url('./assets/images/Formao_puce4.svg');
}

#formao ul li ul ul ul ul {
  list-style-image: url('./assets/images/Formao_puce5.svg');
}

#formao ul li ul ul ul ul ul {
  list-style-image: url('./assets/images/Formao_puce6.svg');
}

#formao ol {
  padding-left: 20px;
  margin-bottom: 20px;
  list-style: none;
  counter-reset: item;
}

#formao ol li {
  display: block;
  font-size: 16px;
  counter-increment: li;
}

#formao ol li::before {
  font-family: 'Nunito', sans-serif !important;
  color: #315397;
  font-weight: 400;
  font-size: 16px;
  font-weight: 600;
  width: 1.5em;
  margin-left: -1em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
  content: counters(item, '.') '. ';
  counter-increment: item;
}

#formao ol li p {
  display: inline;
  margin-bottom: 0;
}

#formao ol li ol {
  margin: 0;
}

#formao figure {
  margin: 0 auto 20px auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#formao figure[data-video] {
  width: 100%;
  max-width: 800px;
}

#formao figure[data-video] div > div:first-child {
  height: 100%;
}

#formao figure img {
  max-width: 100%;
  height: auto;
  display: block;
  padding: 10px;
  margin: 0 auto;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#formao figure video {
  display: block;
  margin: 0 auto 20px auto;
  aspect-ratio: 16/9;
  width: 100%;
  max-width: 800px;
  height: auto;
  background-color: #000;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#formao figure[data-video] .vjs-fullscreen video {
  max-width: none;
}

@supports not (aspect-ratio: 16 / 9) {
  #formao figure video::before {
    float: left;
    padding-top: 56.25%;
    content: '';
  }

  #formao figure video::after {
    display: block;
    content: '';
    clear: both;
  }
}

#formao figure figcaption {
  margin-top: 10px;
  text-align: center;
  font-style: italic;
  color: #6a6a94;
  font-weight: 400;
  font-size: 16px;
}

#formao mark {
  padding: 0.05em 0.2em;
  color: #454560;
  border-radius: 2px;
  background-color: attr(data-color hex-color, red);
}

#formao blockquote {
  border-left: 2px solid #454560;
  padding-left: 20px;
  margin-bottom: 20px;
}

#formao blockquote p {
  font-size: 16px;
  display: inline-block;
  color: #454560;
  font-weight: 300;
  font-style: italic;
}

#formao blockquote p::before {
  content: '«' '\\00a0';
  display: inline-block;
  vertical-align: bottom;
  position: relative;
}

#formao blockquote p::after {
  content: '\\00a0''»';
  display: inline-block;
  vertical-align: bottom;
  position: relative;
}

#formao blockquote > :last-child {
  margin-bottom: 0 !important;
}

#formao div.important {
  margin: 40px;
  padding: 24px;
  border: 2px solid #315397;
  background-color: #D7D9EB;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 16px;
}

#formao div.important::before {
  content: url('./assets/images/Formao_info.svg') '\00a0 \00a0 À retenir';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.5em;
  font-weight: 500;
  color: #315397;
}

#formao div.important > :last-child {
  margin-bottom: 0 !important;
}

#formao div.important *:not(pre, pre *, code, code *) {
  color: #315397 !important;
}

#formao div[data-vimeo-video] iframe {
  display: block;
  margin: 0 auto 20px auto;
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 800px;
  height: auto;
  background-color: #000;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#formao div[data-youtube-video] iframe {
  display: block;
  margin: 0 auto 20px auto;
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 800px;
  height: auto;
  background-color: #000;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#formao > :last-child {
  margin-bottom: 0 !important;
}

#formao pre {
  background-color: #2f2a2a;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 2px;
  padding: 10px;
  font-family: 'JetBrains Mono', monospace !important;
  font-size: 14px;
}

#formao pre code {
  background-color: transparent;
  padding: 0 !important;
  font-family: 'JetBrains Mono', monospace !important;
  font-size: 14px;
}

#formao code {
  background-color: #2f2a2a;
  color: #fff;
  padding: 0.17em 0.2em;
  border-radius: 2px;
  font-family: 'JetBrains Mono', monospace !important;
  font-size: 14px;
  text-shadow: none;
}

#formao code * {
  font-family: 'JetBrains Mono', monospace !important;
}

#formao table {
  border: 2px solid #315397;
  border-collapse: collapse;
  margin-bottom: 20px;
  width: 100%;
}

#formao table tbody {}

#formao table tbody tr {}

#formao table tbody tr th {
  background-color: #6777B0;
  border: 2px solid #315397;
  border-collapse: collapse;
  padding: 5px 10px;
  vertical-align: middle;
  color: #fff;
  font-weight: 600;
}

#formao table tbody tr th p {
  text-align: left;
  color: #fff;
  font-weight: 600;
}

#formao table tbody tr th *:last-child {
  margin-bottom: 0;
  color: #fff !important;
  font-weight: 600;
}

#formao table tbody tr td {
  border: 2px solid #315397;
  border-collapse: collapse;
  padding: 5px 10px;
  vertical-align: middle;
}

#formao table tbody tr td p {
  text-align: left;
}

#formao table tbody tr td *:last-child {
  margin-bottom: 0;
}
