.editor-imaginelab-workshop h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 3em;
  font-weight: 700;
  color: #454560;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .editor-imaginelab-workshop h2 {
    font-size: 2.2em;
  }
}

.editor-imaginelab-workshop h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 2em;
  font-weight: 600;
  color: #315397;
  margin-bottom: 20px;
}

.editor-imaginelab-workshop h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  color: #315397;
  margin-bottom: 20px;
}

.editor-imaginelab-workshop p {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #454560;
  margin-bottom: 20px;
}

.editor-imaginelab-workshop a {
  color: #315397;
  text-decoration: none;
  position: relative;
  transition: color 0.3s;
}

.editor-imaginelab-workshop a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #315397;
  height: 2px;
  transition: background 0.3s;
}

.editor-imaginelab-workshop a:hover {
  color: #E73333;
}

.editor-imaginelab-workshop a:hover::after {
  background-color: #E73333;
}

.editor-imaginelab-workshop ul, .editor-imaginelab-workshop ol {
  padding-left: 20px;
  margin-bottom: 20px;
}

.editor-imaginelab-workshop ul {
  list-style: none;
}

.editor-imaginelab-workshop ul li ul {
  margin: 0;
}

.editor-imaginelab-workshop ol {
  list-style: none;
  counter-reset: item;
}

.editor-imaginelab-workshop li p {
  display: inline;
  margin-bottom: 0;
}

.editor-imaginelab-workshop ol li {
  counter-increment: li;
  display: block;

  
}

.editor-imaginelab-workshop ol li ol {
  margin: 0;
}

.editor-imaginelab-workshop li::before {
  font-family: 'Nunito', sans-serif;
  color: #315397;
  font-weight: 600;
  width: 1.5em;
  margin-left: -1em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
}

.editor-imaginelab-workshop ol li::before {
  content: counters(item, '.') '. ';
  counter-increment: item;
  font-size: 16px;
}

.editor-imaginelab-workshop figure {
  margin: 0 auto 20px auto;
  position: relative;
}

.editor-imaginelab-workshop figure img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* .editor-imaginelab-workshop figure:hover::before {
  display: block;
  position: absolute;
  content: 'Supprimer';
  top: -20px;
  left: 0;
  right: 0;
  height: 20px;
  background-color: rgba(0,0,0,.5);
  z-index: 5;
} */

.editor-imaginelab-workshop figure figcaption {
  text-align: center;
  font-style: italic;
  color: #6a6a94;
  font-weight: 400;
  font-size: 16px;
}

.editor-imaginelab-workshop mark {
  padding: 0.05em 0.2em;
  color: #454560;
  border-radius: 2px;
}

.editor-imaginelab-workshop blockquote {
  border-left: 2px solid #454560;
  padding-left: 20px;
  margin-bottom: 20px;
}

.editor-imaginelab-workshop blockquote p {
  font-size: 16px;
  display: inline-block;
  color: #454560;
  font-weight: 300;
  font-style: italic;
}

.editor-imaginelab-workshop blockquote p:before {
  content: '«' "\00a0";
  display: inline-block;
  vertical-align: bottom;
  position: relative;
}

.editor-imaginelab-workshop blockquote p:after {
  content: "\00a0" "»";
  display: inline-block;
  vertical-align: bottom;
  position: relative;
}

.editor-imaginelab-workshop blockquote > :last-child {
  margin-bottom: 0 !important;
}

.editor-imaginelab-workshop div.important {
  margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #e73333;
	background-color: #FEC8C0;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	border-top-left-radius: 16px;
}

.editor-imaginelab-workshop div.important::before {
  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Calque_2' xmlns='http://www.w3.org/2000/svg' height='32' viewBox='0 0 34.2 88.7'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:%23e73333;%7D.cls-2%7Bopacity:.5;%7D%3C/style%3E%3C/defs%3E%3Cg id='Pictogrammes'%3E%3Cg%3E%3Cg%3E%3Cpath class='cls-2' d='M27.55,6H11.64L6.13,15.53c-1.52,2.63-1.52,5.87,0,8.5l5.5,9.53h15.92l5.5-9.53c1.52-2.63,1.52-5.87,0-8.5l-5.5-9.53Z'/%3E%3Crect class='cls-2' x='11.64' y='47.35' width='15.92' height='41.35'/%3E%3C/g%3E%3Cg%3E%3Cpath class='cls-1' d='M6.9,30.57c.36,.62,1.02,1,1.73,1h15.92c.71,0,1.38-.38,1.73-1l5.5-9.53c1.87-3.24,1.87-7.26,0-10.5L26.29,1c-.36-.62-1.02-1-1.73-1H8.64c-.71,0-1.38,.38-1.73,1L1.4,10.53c-1.87,3.24-1.87,7.26,0,10.5l5.5,9.53ZM4.87,12.53L9.79,4h13.61l4.93,8.53c1.16,2.01,1.16,4.5,0,6.5l-4.92,8.53H9.79l-4.92-8.53c-1.16-2.01-1.16-4.5,0-6.5Z'/%3E%3Cpath class='cls-1' d='M24.55,86.7H8.64c-1.1,0-2-.9-2-2V43.35c0-1.1,.9-2,2-2h15.92c1.1,0,2,.9,2,2v41.35c0,1.1-.9,2-2,2Zm-13.92-4h11.92V45.35H10.64v37.35Z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") " \00a0 À retenir";
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  color: #e73333;
}

.editor-imaginelab-workshop div.important li::before {
  color: #e73333;
}

.editor-imaginelab-workshop div.important > :last-child {
  margin-bottom: 0 !important;
}
