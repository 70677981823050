:root {
  --grid-cell-size-1-24: 4.166666666666666%;
  --grid-cell-size-2-24: 8.333333333333332%;
  --grid-cell-size-3-24: 12.5%;
  --grid-cell-size-4-24: 16.666666666666664%;
  --grid-cell-size-5-24: 20.833333333333336%;
  --grid-cell-size-6-24: 25%;
  --grid-cell-size-7-24: 29.166666666666668%;
  --grid-cell-size-8-24: 33.33333333333333%;
  --grid-cell-size-9-24: 37.5%;
  --grid-cell-size-10-24: 41.66666666666667%;
  --grid-cell-size-11-24: 45.83333333333333%;
  --grid-cell-size-12-24: 50%;
  --grid-cell-size-13-24: 54.166666666666664%;
  --grid-cell-size-14-24: 58.333333333333336%;
  --grid-cell-size-15-24: 62.5%;
  --grid-cell-size-16-24: 66.66666666666666%;
  --grid-cell-size-17-24: 70.83333333333334%;
  --grid-cell-size-18-24: 75%;
  --grid-cell-size-19-24: 79.16666666666666%;
  --grid-cell-size-20-24: 83.33333333333334%;
  --grid-cell-size-21-24: 87.5%;
  --grid-cell-size-22-24: 91.66666666666666%;
  --grid-cell-size-23-24: 95.83333333333334%;
  --grid-cell-size-24-24: 100%;
  --grid-cell-order-1: 1;
  --grid-cell-order-2: 2;
  --grid-cell-order-3: 3;
  --grid-cell-order-4: 4;
  --grid-cell-order-5: 5;
  --grid-cell-order-6: 6;
  --grid-cell-order-7: 7;
  --grid-cell-order-8: 8;
  --grid-cell-order-9: 9;
  --grid-cell-order-10: 10;
  --grid-cell-order-11: 11;
  --grid-cell-order-12: 12;
  --grid-cell-order-13: 13;
  --grid-cell-order-14: 14;
  --grid-cell-order-15: 15;
  --grid-cell-order-16: 16;
  --grid-cell-order-17: 17;
  --grid-cell-order-18: 18;
  --grid-cell-order-19: 19;
  --grid-cell-order-20: 20;
  --grid-cell-order-21: 21;
  --grid-cell-order-22: 22;
  --grid-cell-order-23: 23;
  --grid-cell-order-24: 24;
}

.editor-root.error {
  -webkit-transition: border 0.3s;
  -o-transition: border 0.3s;
  transition: border 0.3s;
}

.editor-root.error {
  border-color: #ff4d4f;
}

.ProseMirror:focus, .ProseMirror-focused {
  outline: none;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

#editor-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

#editor-tooltip-arrow {
  height : 0;
  width : 0;
  border-top : 10px solid #000000bf;
  border-right : 10px solid transparent;
  border-left : 10px solid transparent;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}

.triangle {
	position: relative;
	background-color: orange;
	text-align: left;
}
.triangle:before,
.triangle:after {
	content: '';
	position: absolute;
	background-color: inherit;
}
.triangle,
.triangle:before,
.triangle:after {
	width:  10em;
	height: 10em;
	border-top-right-radius: 30%;
}

.triangle {
	-webkit-transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
	    -ms-transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
	        transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
}
.triangle:before {
	-webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
	    -ms-transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
	        transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}
.triangle:after {
	-webkit-transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
	    -ms-transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
	        transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}

#editor-tooltip .editor-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  /* padding: 6px 8px; */
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  color: #fff;
  background-color: #000000bf;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px #0000001f,0 6px 16px #00000014,0 9px 28px 8px #0000000d;
          box-shadow: 0 3px 6px -4px #0000001f,0 6px 16px #00000014,0 9px 28px 8px #0000000d;
}

#editor-tooltip .editor-tooltip-button {
  color: #fff;
}

.editor-root {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.editor-root.borderless.disabled {
  border: none;
}

.editor-root.borderless {
  border: none;
}

.editor-root.borderless .editor-menu {
  border: none;
}

.editor-menu {
  padding: 4px 11px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fafafa;
  height: 48.8px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.editor-menu .menu-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.editor {
  padding: 4px 11px;
}

.editor-button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
  text-align: center !important;
}

.editor-preview {
  padding: 4px 11px;
}

.upload-input {
  display: block;
  width: 100%;
}

.upload-input .ant-upload {
  width: 100%;
}

.upload-input .ant-btn {
  text-align: left;
}

.editor-root .code-block {
  position: relative;
}

.editor-root .code-block .code-bock-select {
  position: absolute;
  top: 10px;
  right: 10px;
}

.editor-root pre {
  background-color: #2f2a2a;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 2px;
  padding: 10px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
}

.editor-root code {
  background-color: #2f2a2a;
  color: #fff;
  padding: 0.17em 0.2em;
  border-radius: 2px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
}

.editor-root pre code {
  background-color: transparent;
  padding: 0 !important;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
}

.editor-root .editor > :last-child {
  margin-bottom: 0 !important;
}

.editor-highlight .ant-popover-inner-content {
  padding: 5px;
}

.editor-highlight-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.editor-highlight-option {
  width: 20px; 
  min-width: 20px; 
  max-width: 20px; 
  height: 20px; 
  min-height: 20px; 
  max-height: 20px; 
  display: block;
  margin: 5px;
  cursor: pointer;
}

.editor-color .ant-popover-inner-content {
  padding: 5px;
}

.editor-color-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.editor-color-option {
  width: 20px; 
  min-width: 20px; 
  max-width: 20px; 
  height: 20px; 
  min-height: 20px; 
  max-height: 20px; 
  display: block;
  margin: 5px;
  cursor: pointer;
}

.editor-root .editor figure img {
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
          box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.editor-root .editor figcaption {
  border: 1px dashed #000;
  text-align: center;
  font-style: italic;
}

.editor-root .editor figcaption[data-placeholder]::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  height: 0;
  pointer-events: none;
}

.editor-root .editor figure[data-mobile-hidden="true"] {
  opacity: 0.3;
}

.editor-root .editor figure[data-mobile-fit="contain"] img {
  -o-object-fit: contain;
     object-fit: contain;
}

.editor-root .editor figure[data-mobile-fit="fill"] img {
    -o-object-fit: fill;
       object-fit: fill;
}

.editor-root .editor figure[data-mobile-fit="cover"] img {
    -o-object-fit: cover;
       object-fit: cover;
}

@media screen and (min-width: 768px) {
  .editor-root .editor figure[data-tablet-hidden="true"] {
    opacity: 0.3;
  }

  .editor-root .editor figure[data-mobile-hidden="true"][data-tablet-hidden="false"] {
    display: block;
  }

  .editor-root .editor figure[data-tablet-fit="contain"] img {
    -o-object-fit: contain;
       object-fit: contain;
  }

  .editor-root .editor figure[data-tablet-fit="fill"] img {
      -o-object-fit: fill;
         object-fit: fill;
  }

  .editor-root .editor figure[data-tablet-fit="cover"] img {
      -o-object-fit: cover;
         object-fit: cover;
  }
}

@media screen and (min-width: 992px) {
  .editor-root .editor figure[data-desktop-hidden="true"] {
    opacity: 0.3;
  }

  .editor-root .editor figure[data-mobile-hidden="true"][data-desktop-hidden="false"], .editor-root .editor figure[data-tablet-hidden="true"][data-desktop-hidden="false"] {
    display: block;
  }

  .editor-root .editor figure[data-desktop-fit="contain"] img {
    -o-object-fit: contain;
       object-fit: contain;
  }

  .editor-root .editor figure[data-desktop-fit="fill"] img {
      -o-object-fit: fill;
         object-fit: fill;
  }

  .editor-root .editor figure[data-desktop-fit="cover"] img {
      -o-object-fit: cover;
         object-fit: cover;
  }
}

.editor-root a {
  cursor: text;
}

.cloudinary-video {}

.cloudinary-video-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  gap: 12px;
}

.cloudinary-video-caption {
  width: 100%;
}

.editor-root .editor table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.editor-root .editor table tbody tr td {
  border: 2px solid #315397;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 1em;
  padding: 3px 5px;
  position: relative;
  vertical-align: middle;
}

.editor-root .editor table tbody tr th {
  background: #6777b0;
  font-weight: bold;
  text-align: left;
  border: 2px solid #315397;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 1em;
  padding: 3px 5px;
  vertical-align: middle;
}

.editor-root .editor table tbody tr th p {
  color: #fff;
  font-weight: 600;
}

.editor-root .editor table tbody tr th > *, .editor-root .editor table tbody tr td > * {
  margin-bottom: 0;
}

.editor-root .editor table .column-resize-handle {
  background-color: #e73333;
  bottom: -2px;
  position: absolute;
  right: -2px;
  pointer-events: none;
  top: 0;
  width: 4px;
}

.editor-root .editor table p {
  margin: 0;
}

.editor-root .selectedCell:after {
  background: rgba(200, 200, 255, 0.4);
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.editor-root .tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.editor-root .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.editor-root .details-container {
  border: 1px solid #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  padding: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

.editor-root .details-container .details {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.editor-root .details-container .details .details-summary {
  list-style: none;
  position: relative;
  margin-bottom: 0;
}

.editor-root .details-container .details .details-summary::-webkit-details-marker {
  display: none;
}

.editor-root .details-container .details .details-summary::marker {
  display: none;
}

.editor-root .details-container .details .details-summary.is-empty::before {
  content: attr(data-placeholder);
  color: lightgray;
  float: left;
  pointer-events: none;
  height: 0;
}

.editor-root .details-container .details .details-content {
  display: none;
  border-top: 1px solid #000;
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 0;
}

.editor-root .details-container .details .details-content > :last-child {
  margin-bottom: 0;
}

.editor-root .details-container .details.is-open .details-content {
  display: block;
  position: relative;
}

.editor-root .grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.editor-root .grid .grid-cell .grid-cell-content {
  border: 1px gray dashed;
  padding: 8px;
}

.editor-root .grid .grid-cell * {
  max-width: 100%;
}

.editor-root .grid .grid-cell > *:last-child, .editor-root .grid .grid-cell .react-renderer > *:last-child {
  margin-bottom: 0;
}

.editor-root .grid .grid-cell > .react-renderer:not(:last-child) {
  margin-bottom: 20px;
}

.editor-root .grid[data-gap-y="1"] {
  row-gap: 1px;
}

.editor-root .grid[data-gap-y="2"] {
  row-gap: 2px;
}

.editor-root .grid[data-gap-y="3"] {
  row-gap: 3px;
}

.editor-root .grid[data-gap-y="4"] {
  row-gap: 4px;
}

.editor-root .grid[data-gap-y="5"] {
  row-gap: 5px;
}

.editor-root .grid[data-gap-y="6"] {
  row-gap: 6px;
}

.editor-root .grid[data-gap-y="7"] {
  row-gap: 7px;
}

.editor-root .grid[data-gap-y="8"] {
  row-gap: 8px;
}

.editor-root .grid[data-gap-y="9"] {
  row-gap: 9px;
}

.editor-root .grid[data-gap-y="10"] {
  row-gap: 10px;
}

.editor-root .grid[data-gap-y="11"] {
  row-gap: 11px;
}

.editor-root .grid[data-gap-y="12"] {
  row-gap: 12px;
}

.editor-root .grid[data-gap-y="13"] {
  row-gap: 13px;
}

.editor-root .grid[data-gap-y="14"] {
  row-gap: 14px;
}

.editor-root .grid[data-gap-y="15"] {
  row-gap: 15px;
}

.editor-root .grid[data-gap-y="16"] {
  row-gap: 16px;
}

.editor-root .grid[data-gap-y="17"] {
  row-gap: 17px;
}

.editor-root .grid[data-gap-y="18"] {
  row-gap: 18px;
}

.editor-root .grid[data-gap-y="19"] {
  row-gap: 19px;
}

.editor-root .grid[data-gap-y="20"] {
  row-gap: 20px;
}

.editor-root .grid[data-gap-y="21"] {
  row-gap: 21px;
}

.editor-root .grid[data-gap-y="22"] {
  row-gap: 22px;
}

.editor-root .grid[data-gap-y="23"] {
  row-gap: 23px;
}

.editor-root .grid[data-gap-y="24"] {
  row-gap: 24px;
}

.editor-root .grid[data-gap-x="1"] {
  margin-left: -0.5px;
  margin-right: -0.5px;
}

.editor-root .grid[data-gap-x="1"] .grid-cell {
  padding-left: 0.5px;
  padding-right: 0.5px;
}

.editor-root .grid[data-gap-x="2"] {
  margin-left: -1px;
  margin-right: -1px;
}

.editor-root .grid[data-gap-x="2"] .grid-cell {
  padding-left: 1px;
  padding-right: 1px;
}

.editor-root .grid[data-gap-x="3"] {
  margin-left: -1.5px;
  margin-right: -1.5px;
}

.editor-root .grid[data-gap-x="3"] .grid-cell {
  padding-left: 1.5px;
  padding-right: 1.5px;
}

.editor-root .grid[data-gap-x="4"] {
  margin-left: -2px;
  margin-right: -2px;
}

.editor-root .grid[data-gap-x="4"] .grid-cell {
  padding-left: 2px;
  padding-right: 2px;
}

.editor-root .grid[data-gap-x="5"] {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.editor-root .grid[data-gap-x="5"] .grid-cell {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.editor-root .grid[data-gap-x="6"] {
  margin-left: -3px;
  margin-right: -3px;
}

.editor-root .grid[data-gap-x="6"] .grid-cell {
  padding-left: 3px;
  padding-right: 3px;
}

.editor-root .grid[data-gap-x="7"] {
  margin-left: -3.5px;
  margin-right: -3.5px;
}

.editor-root .grid[data-gap-x="7"] .grid-cell {
  padding-left: 3.5px;
  padding-right: 3.5px;
}

.editor-root .grid[data-gap-x="8"] {
  margin-left: -4px;
  margin-right: -4px;
}

.editor-root .grid[data-gap-x="8"] .grid-cell {
  padding-left: 4px;
  padding-right: 4px;
}

.editor-root .grid[data-gap-x="9"] {
  margin-left: -4.5px;
  margin-right: -4.5px;
}

.editor-root .grid[data-gap-x="9"] .grid-cell {
  padding-left: 4.5px;
  padding-right: 4.5px;
}

.editor-root .grid[data-gap-x="10"] {
  margin-left: -5px;
  margin-right: -5px;
}

.editor-root .grid[data-gap-x="10"] .grid-cell {
  padding-left: 5px;
  padding-right: 5px;
}

.editor-root .grid[data-gap-x="11"] {
  margin-left: -5.5px;
  margin-right: -5.5px;
}

.editor-root .grid[data-gap-x="11"] .grid-cell {
  padding-left: 5.5px;
  padding-right: 5.5px;
}

.editor-root .grid[data-gap-x="12"] {
  margin-left: -6px;
  margin-right: -6px;
}

.editor-root .grid[data-gap-x="12"] .grid-cell {
  padding-left: 6px;
  padding-right: 6px;
}

.editor-root .grid[data-gap-x="13"] {
  margin-left: -6.5px;
  margin-right: -6.5px;
}

.editor-root .grid[data-gap-x="13"] .grid-cell {
  padding-left: 6.5px;
  padding-right: 6.5px;
}

.editor-root .grid[data-gap-x="14"] {
  margin-left: -7px;
  margin-right: -7px;
}

.editor-root .grid[data-gap-x="14"] .grid-cell {
  padding-left: 7px;
  padding-right: 7px;
}

.editor-root .grid[data-gap-x="15"] {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.editor-root .grid[data-gap-x="15"] .grid-cell {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.editor-root .grid[data-gap-x="16"] {
  margin-left: -8px;
  margin-right: -8px;
}

.editor-root .grid[data-gap-x="16"] .grid-cell {
  padding-left: 8px;
  padding-right: 8px;
}

.editor-root .grid[data-gap-x="17"] {
  margin-left: -8.5px;
  margin-right: -8.5px;
}

.editor-root .grid[data-gap-x="17"] .grid-cell {
  padding-left: 8.5px;
  padding-right: 8.5px;
}

.editor-root .grid[data-gap-x="18"] {
  margin-left: -9px;
  margin-right: -9px;
}

.editor-root .grid[data-gap-x="18"] .grid-cell {
  padding-left: 9px;
  padding-right: 9px;
}

.editor-root .grid[data-gap-x="19"] {
  margin-left: -9.5px;
  margin-right: -9.5px;
}

.editor-root .grid[data-gap-x="19"] .grid-cell {
  padding-left: 9.5px;
  padding-right: 9.5px;
}

.editor-root .grid[data-gap-x="20"] {
  margin-left: -10px;
  margin-right: -10px;
}

.editor-root .grid[data-gap-x="20"] .grid-cell {
  padding-left: 10px;
  padding-right: 10px;
}

.editor-root .grid[data-gap-x="21"] {
  margin-left: -10.5px;
  margin-right: -10.5px;
}

.editor-root .grid[data-gap-x="21"] .grid-cell {
  padding-left: 10.5px;
  padding-right: 10.5px;
}

.editor-root .grid[data-gap-x="22"] {
  margin-left: -11px;
  margin-right: -11px;
}

.editor-root .grid[data-gap-x="22"] .grid-cell {
  padding-left: 11px;
  padding-right: 11px;
}

.editor-root .grid[data-gap-x="23"] {
  margin-left: -11.5px;
  margin-right: -11.5px;
}

.editor-root .grid[data-gap-x="23"] .grid-cell {
  padding-left: 11.5px;
  padding-right: 11.5px;
}

.editor-root .grid[data-gap-x="24"] {
  margin-left: -12px;
  margin-right: -12px;
}

.editor-root .grid[data-gap-x="24"] .grid-cell {
  padding-left: 12px;
  padding-right: 12px;
}

.editor-root .grid .grid-cell[data-mobile-hidden="true"] {
  display: none;
}

.editor-root .grid .grid-cell[data-mobile-size="1"] {
  -webkit-box-flex: var(--grid-cell-size-1-24);
      -ms-flex: var(--grid-cell-size-1-24);
          flex: var(--grid-cell-size-1-24);
}

.editor-root .grid .grid-cell[data-mobile-size="2"] {
  -webkit-box-flex: var(--grid-cell-size-2-24);
      -ms-flex: var(--grid-cell-size-2-24);
          flex: var(--grid-cell-size-2-24);
}

.editor-root .grid .grid-cell[data-mobile-size="3"] {
  -webkit-box-flex: var(--grid-cell-size-3-24);
      -ms-flex: var(--grid-cell-size-3-24);
          flex: var(--grid-cell-size-3-24);
}

.editor-root .grid .grid-cell[data-mobile-size="4"] {
  -webkit-box-flex: var(--grid-cell-size-4-24);
      -ms-flex: var(--grid-cell-size-4-24);
          flex: var(--grid-cell-size-4-24);
}

.editor-root .grid .grid-cell[data-mobile-size="5"] {
  -webkit-box-flex: var(--grid-cell-size-5-24);
      -ms-flex: var(--grid-cell-size-5-24);
          flex: var(--grid-cell-size-5-24);
}

.editor-root .grid .grid-cell[data-mobile-size="6"] {
  -webkit-box-flex: var(--grid-cell-size-6-24);
      -ms-flex: var(--grid-cell-size-6-24);
          flex: var(--grid-cell-size-6-24);
}

.editor-root .grid .grid-cell[data-mobile-size="7"] {
  -webkit-box-flex: var(--grid-cell-size-7-24);
      -ms-flex: var(--grid-cell-size-7-24);
          flex: var(--grid-cell-size-7-24);
}

.editor-root .grid .grid-cell[data-mobile-size="8"] {
  -webkit-box-flex: var(--grid-cell-size-8-24);
      -ms-flex: var(--grid-cell-size-8-24);
          flex: var(--grid-cell-size-8-24);
}

.editor-root .grid .grid-cell[data-mobile-size="9"] {
  -webkit-box-flex: var(--grid-cell-size-9-24);
      -ms-flex: var(--grid-cell-size-9-24);
          flex: var(--grid-cell-size-9-24);
}

.editor-root .grid .grid-cell[data-mobile-size="10"] {
  -webkit-box-flex: var(--grid-cell-size-10-24);
      -ms-flex: var(--grid-cell-size-10-24);
          flex: var(--grid-cell-size-10-24);
}

.editor-root .grid .grid-cell[data-mobile-size="11"] {
  -webkit-box-flex: var(--grid-cell-size-11-24);
      -ms-flex: var(--grid-cell-size-11-24);
          flex: var(--grid-cell-size-11-24);
}

.editor-root .grid .grid-cell[data-mobile-size="12"] {
  -webkit-box-flex: var(--grid-cell-size-12-24);
      -ms-flex: var(--grid-cell-size-12-24);
          flex: var(--grid-cell-size-12-24);
}

.editor-root .grid .grid-cell[data-mobile-size="13"] {
  -webkit-box-flex: var(--grid-cell-size-13-24);
      -ms-flex: var(--grid-cell-size-13-24);
          flex: var(--grid-cell-size-13-24);
}

.editor-root .grid .grid-cell[data-mobile-size="14"] {
  -webkit-box-flex: var(--grid-cell-size-14-24);
      -ms-flex: var(--grid-cell-size-14-24);
          flex: var(--grid-cell-size-14-24);
}

.editor-root .grid .grid-cell[data-mobile-size="15"] {
  -webkit-box-flex: var(--grid-cell-size-15-24);
      -ms-flex: var(--grid-cell-size-15-24);
          flex: var(--grid-cell-size-15-24);
}

.editor-root .grid .grid-cell[data-mobile-size="16"] {
  -webkit-box-flex: var(--grid-cell-size-16-24);
      -ms-flex: var(--grid-cell-size-16-24);
          flex: var(--grid-cell-size-16-24);
}

.editor-root .grid .grid-cell[data-mobile-size="17"] {
  -webkit-box-flex: var(--grid-cell-size-17-24);
      -ms-flex: var(--grid-cell-size-17-24);
          flex: var(--grid-cell-size-17-24);
}

.editor-root .grid .grid-cell[data-mobile-size="18"] {
  -webkit-box-flex: var(--grid-cell-size-18-24);
      -ms-flex: var(--grid-cell-size-18-24);
          flex: var(--grid-cell-size-18-24);
}

.editor-root .grid .grid-cell[data-mobile-size="19"] {
  -webkit-box-flex: var(--grid-cell-size-19-24);
      -ms-flex: var(--grid-cell-size-19-24);
          flex: var(--grid-cell-size-19-24);
}

.editor-root .grid .grid-cell[data-mobile-size="20"] {
  -webkit-box-flex: var(--grid-cell-size-20-24);
      -ms-flex: var(--grid-cell-size-20-24);
          flex: var(--grid-cell-size-20-24);
}

.editor-root .grid .grid-cell[data-mobile-size="21"] {
  -webkit-box-flex: var(--grid-cell-size-21-24);
      -ms-flex: var(--grid-cell-size-21-24);
          flex: var(--grid-cell-size-21-24);
}

.editor-root .grid .grid-cell[data-mobile-size="22"] {
  -webkit-box-flex: var(--grid-cell-size-22-24);
      -ms-flex: var(--grid-cell-size-22-24);
          flex: var(--grid-cell-size-22-24);
}

.editor-root .grid .grid-cell[data-mobile-size="23"] {
  -webkit-box-flex: var(--grid-cell-size-23-24);
      -ms-flex: var(--grid-cell-size-23-24);
          flex: var(--grid-cell-size-23-24);
}

.editor-root .grid .grid-cell[data-mobile-size="24"] {
  -webkit-box-flex: var(--grid-cell-size-24-24);
      -ms-flex: var(--grid-cell-size-24-24);
          flex: var(--grid-cell-size-24-24);
}

.editor-root .grid .grid-cell[data-mobile-order="1"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-1);
      -ms-flex-order: var(--grid-cell-order-1);
          order: var(--grid-cell-order-1);
}

.editor-root .grid .grid-cell[data-mobile-order="2"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-2);
      -ms-flex-order: var(--grid-cell-order-2);
          order: var(--grid-cell-order-2);
}

.editor-root .grid .grid-cell[data-mobile-order="3"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-3);
      -ms-flex-order: var(--grid-cell-order-3);
          order: var(--grid-cell-order-3);
}

.editor-root .grid .grid-cell[data-mobile-order="4"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-4);
      -ms-flex-order: var(--grid-cell-order-4);
          order: var(--grid-cell-order-4);
}

.editor-root .grid .grid-cell[data-mobile-order="5"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-5);
      -ms-flex-order: var(--grid-cell-order-5);
          order: var(--grid-cell-order-5);
}

.editor-root .grid .grid-cell[data-mobile-order="6"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-6);
      -ms-flex-order: var(--grid-cell-order-6);
          order: var(--grid-cell-order-6);
}

.editor-root .grid .grid-cell[data-mobile-order="7"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-7);
      -ms-flex-order: var(--grid-cell-order-7);
          order: var(--grid-cell-order-7);
}

.editor-root .grid .grid-cell[data-mobile-order="8"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-8);
      -ms-flex-order: var(--grid-cell-order-8);
          order: var(--grid-cell-order-8);
}

.editor-root .grid .grid-cell[data-mobile-order="9"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-9);
      -ms-flex-order: var(--grid-cell-order-9);
          order: var(--grid-cell-order-9);
}

.editor-root .grid .grid-cell[data-mobile-order="10"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-10);
      -ms-flex-order: var(--grid-cell-order-10);
          order: var(--grid-cell-order-10);
}

.editor-root .grid .grid-cell[data-mobile-order="11"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-11);
      -ms-flex-order: var(--grid-cell-order-11);
          order: var(--grid-cell-order-11);
}

.editor-root .grid .grid-cell[data-mobile-order="12"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-12);
      -ms-flex-order: var(--grid-cell-order-12);
          order: var(--grid-cell-order-12);
}

.editor-root .grid .grid-cell[data-mobile-order="13"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-13);
      -ms-flex-order: var(--grid-cell-order-13);
          order: var(--grid-cell-order-13);
}

.editor-root .grid .grid-cell[data-mobile-order="14"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-14);
      -ms-flex-order: var(--grid-cell-order-14);
          order: var(--grid-cell-order-14);
}

.editor-root .grid .grid-cell[data-mobile-order="15"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-15);
      -ms-flex-order: var(--grid-cell-order-15);
          order: var(--grid-cell-order-15);
}

.editor-root .grid .grid-cell[data-mobile-order="16"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-16);
      -ms-flex-order: var(--grid-cell-order-16);
          order: var(--grid-cell-order-16);
}

.editor-root .grid .grid-cell[data-mobile-order="17"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-17);
      -ms-flex-order: var(--grid-cell-order-17);
          order: var(--grid-cell-order-17);
}

.editor-root .grid .grid-cell[data-mobile-order="18"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-18);
      -ms-flex-order: var(--grid-cell-order-18);
          order: var(--grid-cell-order-18);
}

.editor-root .grid .grid-cell[data-mobile-order="19"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-19);
      -ms-flex-order: var(--grid-cell-order-19);
          order: var(--grid-cell-order-19);
}

.editor-root .grid .grid-cell[data-mobile-order="20"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-20);
      -ms-flex-order: var(--grid-cell-order-20);
          order: var(--grid-cell-order-20);
}

.editor-root .grid .grid-cell[data-mobile-order="21"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-21);
      -ms-flex-order: var(--grid-cell-order-21);
          order: var(--grid-cell-order-21);
}

.editor-root .grid .grid-cell[data-mobile-order="22"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-22);
      -ms-flex-order: var(--grid-cell-order-22);
          order: var(--grid-cell-order-22);
}

.editor-root .grid .grid-cell[data-mobile-order="23"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-23);
      -ms-flex-order: var(--grid-cell-order-23);
          order: var(--grid-cell-order-23);
}

.editor-root .grid .grid-cell[data-mobile-order="24"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-24);
      -ms-flex-order: var(--grid-cell-order-24);
          order: var(--grid-cell-order-24);
}

@media screen and (min-width: 768px) {
  .editor-root .grid .grid-cell[data-tablet-hidden="true"] {
    display: none;
  }

  .editor-root .grid .grid-cell[data-mobile-hidden="true"][data-tablet-hidden="false"] {
    display: block;
  }

  .editor-root .grid .grid-cell[data-tablet-size="1"] {
    -webkit-box-flex: var(--grid-cell-size-1-24);
        -ms-flex: var(--grid-cell-size-1-24);
            flex: var(--grid-cell-size-1-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="2"] {
    -webkit-box-flex: var(--grid-cell-size-2-24);
        -ms-flex: var(--grid-cell-size-2-24);
            flex: var(--grid-cell-size-2-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="3"] {
    -webkit-box-flex: var(--grid-cell-size-3-24);
        -ms-flex: var(--grid-cell-size-3-24);
            flex: var(--grid-cell-size-3-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="4"] {
    -webkit-box-flex: var(--grid-cell-size-4-24);
        -ms-flex: var(--grid-cell-size-4-24);
            flex: var(--grid-cell-size-4-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="5"] {
    -webkit-box-flex: var(--grid-cell-size-5-24);
        -ms-flex: var(--grid-cell-size-5-24);
            flex: var(--grid-cell-size-5-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="6"] {
    -webkit-box-flex: var(--grid-cell-size-6-24);
        -ms-flex: var(--grid-cell-size-6-24);
            flex: var(--grid-cell-size-6-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="7"] {
    -webkit-box-flex: var(--grid-cell-size-7-24);
        -ms-flex: var(--grid-cell-size-7-24);
            flex: var(--grid-cell-size-7-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="8"] {
    -webkit-box-flex: var(--grid-cell-size-8-24);
        -ms-flex: var(--grid-cell-size-8-24);
            flex: var(--grid-cell-size-8-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="9"] {
    -webkit-box-flex: var(--grid-cell-size-9-24);
        -ms-flex: var(--grid-cell-size-9-24);
            flex: var(--grid-cell-size-9-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="10"] {
    -webkit-box-flex: var(--grid-cell-size-10-24);
        -ms-flex: var(--grid-cell-size-10-24);
            flex: var(--grid-cell-size-10-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="11"] {
    -webkit-box-flex: var(--grid-cell-size-11-24);
        -ms-flex: var(--grid-cell-size-11-24);
            flex: var(--grid-cell-size-11-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="12"] {
    -webkit-box-flex: var(--grid-cell-size-12-24);
        -ms-flex: var(--grid-cell-size-12-24);
            flex: var(--grid-cell-size-12-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="13"] {
    -webkit-box-flex: var(--grid-cell-size-13-24);
        -ms-flex: var(--grid-cell-size-13-24);
            flex: var(--grid-cell-size-13-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="14"] {
    -webkit-box-flex: var(--grid-cell-size-14-24);
        -ms-flex: var(--grid-cell-size-14-24);
            flex: var(--grid-cell-size-14-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="15"] {
    -webkit-box-flex: var(--grid-cell-size-15-24);
        -ms-flex: var(--grid-cell-size-15-24);
            flex: var(--grid-cell-size-15-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="16"] {
    -webkit-box-flex: var(--grid-cell-size-16-24);
        -ms-flex: var(--grid-cell-size-16-24);
            flex: var(--grid-cell-size-16-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="17"] {
    -webkit-box-flex: var(--grid-cell-size-17-24);
        -ms-flex: var(--grid-cell-size-17-24);
            flex: var(--grid-cell-size-17-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="18"] {
    -webkit-box-flex: var(--grid-cell-size-18-24);
        -ms-flex: var(--grid-cell-size-18-24);
            flex: var(--grid-cell-size-18-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="19"] {
    -webkit-box-flex: var(--grid-cell-size-19-24);
        -ms-flex: var(--grid-cell-size-19-24);
            flex: var(--grid-cell-size-19-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="20"] {
    -webkit-box-flex: var(--grid-cell-size-20-24);
        -ms-flex: var(--grid-cell-size-20-24);
            flex: var(--grid-cell-size-20-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="21"] {
    -webkit-box-flex: var(--grid-cell-size-21-24);
        -ms-flex: var(--grid-cell-size-21-24);
            flex: var(--grid-cell-size-21-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="22"] {
    -webkit-box-flex: var(--grid-cell-size-22-24);
        -ms-flex: var(--grid-cell-size-22-24);
            flex: var(--grid-cell-size-22-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="23"] {
    -webkit-box-flex: var(--grid-cell-size-23-24);
        -ms-flex: var(--grid-cell-size-23-24);
            flex: var(--grid-cell-size-23-24);
  }
  
  .editor-root .grid .grid-cell[data-tablet-size="24"] {
    -webkit-box-flex: var(--grid-cell-size-24-24);
        -ms-flex: var(--grid-cell-size-24-24);
            flex: var(--grid-cell-size-24-24);
  }

  .editor-root .grid .grid-cell[data-tablet-order="1"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-1);
        -ms-flex-order: var(--grid-cell-order-1);
            order: var(--grid-cell-order-1);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="2"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-2);
        -ms-flex-order: var(--grid-cell-order-2);
            order: var(--grid-cell-order-2);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="3"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-3);
        -ms-flex-order: var(--grid-cell-order-3);
            order: var(--grid-cell-order-3);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="4"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-4);
        -ms-flex-order: var(--grid-cell-order-4);
            order: var(--grid-cell-order-4);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="5"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-5);
        -ms-flex-order: var(--grid-cell-order-5);
            order: var(--grid-cell-order-5);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="6"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-6);
        -ms-flex-order: var(--grid-cell-order-6);
            order: var(--grid-cell-order-6);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="7"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-7);
        -ms-flex-order: var(--grid-cell-order-7);
            order: var(--grid-cell-order-7);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="8"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-8);
        -ms-flex-order: var(--grid-cell-order-8);
            order: var(--grid-cell-order-8);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="9"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-9);
        -ms-flex-order: var(--grid-cell-order-9);
            order: var(--grid-cell-order-9);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="10"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-10);
        -ms-flex-order: var(--grid-cell-order-10);
            order: var(--grid-cell-order-10);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="11"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-11);
        -ms-flex-order: var(--grid-cell-order-11);
            order: var(--grid-cell-order-11);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="12"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-12);
        -ms-flex-order: var(--grid-cell-order-12);
            order: var(--grid-cell-order-12);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="13"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-13);
        -ms-flex-order: var(--grid-cell-order-13);
            order: var(--grid-cell-order-13);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="14"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-14);
        -ms-flex-order: var(--grid-cell-order-14);
            order: var(--grid-cell-order-14);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="15"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-15);
        -ms-flex-order: var(--grid-cell-order-15);
            order: var(--grid-cell-order-15);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="16"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-16);
        -ms-flex-order: var(--grid-cell-order-16);
            order: var(--grid-cell-order-16);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="17"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-17);
        -ms-flex-order: var(--grid-cell-order-17);
            order: var(--grid-cell-order-17);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="18"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-18);
        -ms-flex-order: var(--grid-cell-order-18);
            order: var(--grid-cell-order-18);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="19"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-19);
        -ms-flex-order: var(--grid-cell-order-19);
            order: var(--grid-cell-order-19);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="20"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-20);
        -ms-flex-order: var(--grid-cell-order-20);
            order: var(--grid-cell-order-20);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="21"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-21);
        -ms-flex-order: var(--grid-cell-order-21);
            order: var(--grid-cell-order-21);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="22"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-22);
        -ms-flex-order: var(--grid-cell-order-22);
            order: var(--grid-cell-order-22);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="23"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-23);
        -ms-flex-order: var(--grid-cell-order-23);
            order: var(--grid-cell-order-23);
  }
  
  .editor-root .grid .grid-cell[data-tablet-order="24"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-24);
        -ms-flex-order: var(--grid-cell-order-24);
            order: var(--grid-cell-order-24);
  }
}

@media screen and (min-width: 992px) {
  .editor-root .grid .grid-cell, .editor-root .grid .grid-cell[data-mobile-hidden="true"], .editor-root .grid .grid-cell[data-tablet-hidden="true"] {
    display: block;
  }

  .editor-root .grid .grid-cell[data-desktop-size="1"] {
    -webkit-box-flex: var(--grid-cell-size-1-24);
        -ms-flex: var(--grid-cell-size-1-24);
            flex: var(--grid-cell-size-1-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="2"] {
    -webkit-box-flex: var(--grid-cell-size-2-24);
        -ms-flex: var(--grid-cell-size-2-24);
            flex: var(--grid-cell-size-2-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="3"] {
    -webkit-box-flex: var(--grid-cell-size-3-24);
        -ms-flex: var(--grid-cell-size-3-24);
            flex: var(--grid-cell-size-3-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="4"] {
    -webkit-box-flex: var(--grid-cell-size-4-24);
        -ms-flex: var(--grid-cell-size-4-24);
            flex: var(--grid-cell-size-4-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="5"] {
    -webkit-box-flex: var(--grid-cell-size-5-24);
        -ms-flex: var(--grid-cell-size-5-24);
            flex: var(--grid-cell-size-5-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="6"] {
    -webkit-box-flex: var(--grid-cell-size-6-24);
        -ms-flex: var(--grid-cell-size-6-24);
            flex: var(--grid-cell-size-6-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="7"] {
    -webkit-box-flex: var(--grid-cell-size-7-24);
        -ms-flex: var(--grid-cell-size-7-24);
            flex: var(--grid-cell-size-7-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="8"] {
    -webkit-box-flex: var(--grid-cell-size-8-24);
        -ms-flex: var(--grid-cell-size-8-24);
            flex: var(--grid-cell-size-8-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="9"] {
    -webkit-box-flex: var(--grid-cell-size-9-24);
        -ms-flex: var(--grid-cell-size-9-24);
            flex: var(--grid-cell-size-9-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="10"] {
    -webkit-box-flex: var(--grid-cell-size-10-24);
        -ms-flex: var(--grid-cell-size-10-24);
            flex: var(--grid-cell-size-10-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="11"] {
    -webkit-box-flex: var(--grid-cell-size-11-24);
        -ms-flex: var(--grid-cell-size-11-24);
            flex: var(--grid-cell-size-11-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="12"] {
    -webkit-box-flex: var(--grid-cell-size-12-24);
        -ms-flex: var(--grid-cell-size-12-24);
            flex: var(--grid-cell-size-12-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="13"] {
    -webkit-box-flex: var(--grid-cell-size-13-24);
        -ms-flex: var(--grid-cell-size-13-24);
            flex: var(--grid-cell-size-13-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="14"] {
    -webkit-box-flex: var(--grid-cell-size-14-24);
        -ms-flex: var(--grid-cell-size-14-24);
            flex: var(--grid-cell-size-14-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="15"] {
    -webkit-box-flex: var(--grid-cell-size-15-24);
        -ms-flex: var(--grid-cell-size-15-24);
            flex: var(--grid-cell-size-15-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="16"] {
    -webkit-box-flex: var(--grid-cell-size-16-24);
        -ms-flex: var(--grid-cell-size-16-24);
            flex: var(--grid-cell-size-16-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="17"] {
    -webkit-box-flex: var(--grid-cell-size-17-24);
        -ms-flex: var(--grid-cell-size-17-24);
            flex: var(--grid-cell-size-17-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="18"] {
    -webkit-box-flex: var(--grid-cell-size-18-24);
        -ms-flex: var(--grid-cell-size-18-24);
            flex: var(--grid-cell-size-18-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="19"] {
    -webkit-box-flex: var(--grid-cell-size-19-24);
        -ms-flex: var(--grid-cell-size-19-24);
            flex: var(--grid-cell-size-19-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="20"] {
    -webkit-box-flex: var(--grid-cell-size-20-24);
        -ms-flex: var(--grid-cell-size-20-24);
            flex: var(--grid-cell-size-20-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="21"] {
    -webkit-box-flex: var(--grid-cell-size-21-24);
        -ms-flex: var(--grid-cell-size-21-24);
            flex: var(--grid-cell-size-21-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="22"] {
    -webkit-box-flex: var(--grid-cell-size-22-24);
        -ms-flex: var(--grid-cell-size-22-24);
            flex: var(--grid-cell-size-22-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="23"] {
    -webkit-box-flex: var(--grid-cell-size-23-24);
        -ms-flex: var(--grid-cell-size-23-24);
            flex: var(--grid-cell-size-23-24);
  }
  
  .editor-root .grid .grid-cell[data-desktop-size="24"] {
    -webkit-box-flex: var(--grid-cell-size-24-24);
        -ms-flex: var(--grid-cell-size-24-24);
            flex: var(--grid-cell-size-24-24);
  }

  .editor-root .grid .grid-cell[data-desktop-order="1"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-1);
        -ms-flex-order: var(--grid-cell-order-1);
            order: var(--grid-cell-order-1);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="2"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-2);
        -ms-flex-order: var(--grid-cell-order-2);
            order: var(--grid-cell-order-2);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="3"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-3);
        -ms-flex-order: var(--grid-cell-order-3);
            order: var(--grid-cell-order-3);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="4"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-4);
        -ms-flex-order: var(--grid-cell-order-4);
            order: var(--grid-cell-order-4);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="5"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-5);
        -ms-flex-order: var(--grid-cell-order-5);
            order: var(--grid-cell-order-5);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="6"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-6);
        -ms-flex-order: var(--grid-cell-order-6);
            order: var(--grid-cell-order-6);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="7"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-7);
        -ms-flex-order: var(--grid-cell-order-7);
            order: var(--grid-cell-order-7);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="8"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-8);
        -ms-flex-order: var(--grid-cell-order-8);
            order: var(--grid-cell-order-8);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="9"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-9);
        -ms-flex-order: var(--grid-cell-order-9);
            order: var(--grid-cell-order-9);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="10"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-10);
        -ms-flex-order: var(--grid-cell-order-10);
            order: var(--grid-cell-order-10);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="11"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-11);
        -ms-flex-order: var(--grid-cell-order-11);
            order: var(--grid-cell-order-11);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="12"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-12);
        -ms-flex-order: var(--grid-cell-order-12);
            order: var(--grid-cell-order-12);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="13"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-13);
        -ms-flex-order: var(--grid-cell-order-13);
            order: var(--grid-cell-order-13);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="14"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-14);
        -ms-flex-order: var(--grid-cell-order-14);
            order: var(--grid-cell-order-14);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="15"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-15);
        -ms-flex-order: var(--grid-cell-order-15);
            order: var(--grid-cell-order-15);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="16"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-16);
        -ms-flex-order: var(--grid-cell-order-16);
            order: var(--grid-cell-order-16);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="17"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-17);
        -ms-flex-order: var(--grid-cell-order-17);
            order: var(--grid-cell-order-17);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="18"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-18);
        -ms-flex-order: var(--grid-cell-order-18);
            order: var(--grid-cell-order-18);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="19"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-19);
        -ms-flex-order: var(--grid-cell-order-19);
            order: var(--grid-cell-order-19);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="20"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-20);
        -ms-flex-order: var(--grid-cell-order-20);
            order: var(--grid-cell-order-20);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="21"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-21);
        -ms-flex-order: var(--grid-cell-order-21);
            order: var(--grid-cell-order-21);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="22"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-22);
        -ms-flex-order: var(--grid-cell-order-22);
            order: var(--grid-cell-order-22);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="23"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-23);
        -ms-flex-order: var(--grid-cell-order-23);
            order: var(--grid-cell-order-23);
  }
  
  .editor-root .grid .grid-cell[data-desktop-order="24"] {
    -webkit-box-ordinal-group: var(--grid-cell-order-24);
        -ms-flex-order: var(--grid-cell-order-24);
            order: var(--grid-cell-order-24);
  }
}

.editor-grid .editor-grid-container {
  border: 1px solid #d9d9d9;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  height: 100px;
}

.editor-grid .editor-grid-container.editor-grid-container-responsive {
  display: block;
  height: auto;
}

.editor-grid .editor-grid-container.editor-grid-container-dragging .editor-grid-resize-handle:hover {
  border-color: #f0f0f0;
}

.editor-grid .editor-grid-container.editor-grid-container-dragging .editor-grid-resize-handle:active:hover {
  border-color: #69b1ff;
}

.editor-grid .editor-grid-container.editor-grid-container-dragging .editor-grid-cell {
  pointer-events: none;
}

.editor-grid .editor-grid-resize-handle {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 1px;
          flex: 0 0 1px;
  background-color: transparent;
  cursor: ew-resize;
  -ms-touch-action: none;
      touch-action: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-left: 4px dashed #d9d9d9;
}

.editor-grid .editor-grid-resize-handle:hover {
  border-color: #69b1ff;
}

.editor-grid .editor-grid-resize-handle:active {
  border-color: #69b1ff;
}

.editor-grid .editor-grid-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  position: relative;
  height: 100px;
  width: 100%;
}

.editor-grid .editor-grid-row.editor-grid-row-placeholder {
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fafafa;
  border-top: 1px dashed #d9d9d9;
  border-bottom: 1px dashed #d9d9d9;
}

.editor-grid .editor-grid-row.editor-grid-row-placeholder.editor-grid-row-placeholder-start {
  border-top: none;
}

.editor-grid .editor-grid-row.editor-grid-row-placeholder.editor-grid-row-placeholder-end {
  border-bottom: none;
}
.editor-grid .editor-grid-row.editor-grid-row-placeholder.editor-grid-row-placeholder-over {
  background-color: #69b1ff;
}

.editor-grid .editor-grid-row.editor-grid-row-placeholder .editor-grid-row-placeholder-icon {
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.88);
}

.editor-grid .editor-grid-cell {
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  height: 100%;
  border: 2px solid transparent;
}

.editor-grid .editor-grid-cell.editor-grid-cell-hidden {
  background-image: conic-gradient(rgba(0, 0, 0, 0.06) 0 25%, transparent 0 50%, rgba(0, 0, 0, 0.06) 0 75%, transparent 0);
  background-size: 8px 8px;
}

.editor-grid .editor-grid-cell:hover {
  border-color: #69b1ff;
}

.editor-grid .editor-grid-container.editor-grid-container-desktop .editor-grid-cell  {
  cursor: default;
}

.editor-grid .editor-grid-container.editor-grid-container-desktop .editor-grid-cell:hover  {
  border-color: transparent;
}

.editor-grid .preview {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.editor-grid .preview-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.editor-grid .desktop-preview {
  width: 100%;
}

.editor-grid .desktop-preview .desktop-preview-screen {
  width: 100%;
  height: 200px;
  border: 5px solid #000;
  border-radius: 10px;
  padding: 10px;
  overflow: scroll;
}

.editor-grid .desktop-preview .desktop-preview-screen-stand {
  width: 60px;
  height: 50px;
  background-color: #000;
  margin: 0 auto;
}

.editor-grid .desktop-preview .desktop-preview-screen-stand-base {
  width: 100px;
  height: 10px;
  background-color: #000;
  border-radius: 500px;
  margin: 0 auto;
}

.editor-grid .tablet-preview {
}

.editor-grid .tablet-preview .tablet-preview-screen {
  width: 180px;
  height: 230px;
  border: 5px solid #000;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  overflow: scroll;
}

.editor-grid .tablet-preview .tablet-preview-buttons {
  background-color: #000;
  width: 180px;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.editor-grid .tablet-preview .tablet-preview-buttons .tablet-preview-home-button {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 500px;
  margin: 0 auto;
}

.editor-grid .mobile-preview {}

.editor-grid .mobile-preview .mobile-preview-screen {
  width: 100px;
  height: 170px;
  border: 5px solid #000;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  overflow: scroll;
}

.editor-grid .mobile-preview .mobile-preview-buttons {
  background-color: #000;
  width: 100px;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.editor-grid .mobile-preview .mobile-preview-buttons .mobile-preview-home-button{
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 500px;
  margin: 0 auto;
}

.editor-grid .preview-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 10px;
}

.editor-grid .preview-row:empty {
  display: none;
}

.editor-grid .preview-row[data-gap-y="1"] {
  row-gap: 0.5px;
}

.editor-grid .preview-row[data-gap-y="2"] {
  row-gap: 1px;
}

.editor-grid .preview-row[data-gap-y="3"] {
  row-gap: 1.5px;
}

.editor-grid .preview-row[data-gap-y="4"] {
  row-gap: 2px;
}

.editor-grid .preview-row[data-gap-y="5"] {
  row-gap: 2.5px;
}

.editor-grid .preview-row[data-gap-y="6"] {
  row-gap: 3px;
}

.editor-grid .preview-row[data-gap-y="7"] {
  row-gap: 3.5px;
}

.editor-grid .preview-row[data-gap-y="8"] {
  row-gap: 4px;
}

.editor-grid .preview-row[data-gap-y="9"] {
  row-gap: 4.5px;
}

.editor-grid .preview-row[data-gap-y="10"] {
  row-gap: 5px;
}

.editor-grid .preview-row[data-gap-y="11"] {
  row-gap: 5.5px;
}

.editor-grid .preview-row[data-gap-y="12"] {
  row-gap: 6px;
}

.editor-grid .preview-row[data-gap-y="13"] {
  row-gap: 6.5px;
}

.editor-grid .preview-row[data-gap-y="14"] {
  row-gap: 7px;
}

.editor-grid .preview-row[data-gap-y="15"] {
  row-gap: 7.5px;
}

.editor-grid .preview-row[data-gap-y="16"] {
  row-gap: 8px;
}

.editor-grid .preview-row[data-gap-y="17"] {
  row-gap: 8.5px;
}

.editor-grid .preview-row[data-gap-y="18"] {
  row-gap: 9px;
}

.editor-grid .preview-row[data-gap-y="19"] {
  row-gap: 9.5px;
}

.editor-grid .preview-row[data-gap-y="20"] {
  row-gap: 10px;
}

.editor-grid .preview-row[data-gap-y="21"] {
  row-gap: 10.5px;
}

.editor-grid .preview-row[data-gap-y="22"] {
  row-gap: 11px;
}

.editor-grid .preview-row[data-gap-y="23"] {
  row-gap: 11.5px;
}

.editor-grid .preview-row[data-gap-y="24"] {
  row-gap: 12px;
}

.editor-grid .preview-row[data-gap-x="1"] {
  margin-left: -0.25px;
  margin-right: -0.25px;
}

.editor-grid .preview-row[data-gap-x="1"] .preview-cell {
  padding-left: 0.25px;
  padding-right: 0.25px;
}

.editor-grid .preview-row[data-gap-x="2"] {
  margin-left: -0.5px;
  margin-right: -0.5px;
}

.editor-grid .preview-row[data-gap-x="2"] .preview-cell {
  padding-left: 0.5px;
  padding-right: 0.5px;
}

.editor-grid .preview-row[data-gap-x="3"] {
  margin-left: -0.75px;
  margin-right: -0.75px;
}

.editor-grid .preview-row[data-gap-x="3"] .preview-cell {
  padding-left: 0.75px;
  padding-right: 0.75px;
}

.editor-grid .preview-row[data-gap-x="4"] {
  margin-left: -1px;
  margin-right: -1px;
}

.editor-grid .preview-row[data-gap-x="4"] .preview-cell {
  padding-left: 1px;
  padding-right: 1px;
}

.editor-grid .preview-row[data-gap-x="5"] {
  margin-left: -1.25px;
  margin-right: -1.25px;
}

.editor-grid .preview-row[data-gap-x="5"] .preview-cell {
  padding-left: 1.25px;
  padding-right: 1.25px;
}

.editor-grid .preview-row[data-gap-x="6"] {
  margin-left: -1.5px;
  margin-right: -1.5px;
}

.editor-grid .preview-row[data-gap-x="6"] .preview-cell {
  padding-left: 1.5px;
  padding-right: 1.5px;
}

.editor-grid .preview-row[data-gap-x="7"] {
  margin-left: -1.75px;
  margin-right: -1.75px;
}

.editor-grid .preview-row[data-gap-x="7"] .preview-cell {
  padding-left: 1.75px;
  padding-right: 1.75px;
}

.editor-grid .preview-row[data-gap-x="8"] {
  margin-left: -2px;
  margin-right: -2px;
}

.editor-grid .preview-row[data-gap-x="8"] .preview-cell {
  padding-left: 2px;
  padding-right: 2px;
}

.editor-grid .preview-row[data-gap-x="9"] {
  margin-left: -2.25px;
  margin-right: -2.25px;
}

.editor-grid .preview-row[data-gap-x="9"] .preview-cell {
  padding-left: 2.25px;
  padding-right: 2.25px;
}

.editor-grid .preview-row[data-gap-x="10"] {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.editor-grid .preview-row[data-gap-x="10"] .preview-cell {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.editor-grid .preview-row[data-gap-x="11"] {
  margin-left: -2.75px;
  margin-right: -2.75px;
}

.editor-grid .preview-row[data-gap-x="11"] .preview-cell {
  padding-left: 2.75px;
  padding-right: 2.75px;
}

.editor-grid .preview-row[data-gap-x="12"] {
  margin-left: -3px;
  margin-right: -3px;
}

.editor-grid .preview-row[data-gap-x="12"] .preview-cell {
  padding-left: 3px;
  padding-right: 3px;
}

.editor-grid .preview-row[data-gap-x="13"] {
  margin-left: -3.25px;
  margin-right: -3.25px;
}

.editor-grid .preview-row[data-gap-x="13"] .preview-cell {
  padding-left: 3.25px;
  padding-right: 3.25px;
}

.editor-grid .preview-row[data-gap-x="14"] {
  margin-left: -3.5px;
  margin-right: -3.5px;
}

.editor-grid .preview-row[data-gap-x="14"] .preview-cell {
  padding-left: 3.5px;
  padding-right: 3.5px;
}

.editor-grid .preview-row[data-gap-x="15"] {
  margin-left: -3.75px;
  margin-right: -3.75px;
}

.editor-grid .preview-row[data-gap-x="15"] .preview-cell {
  padding-left: 3.75px;
  padding-right: 3.75px;
}

.editor-grid .preview-row[data-gap-x="16"] {
  margin-left: -4px;
  margin-right: -4px;
}

.editor-grid .preview-row[data-gap-x="16"] .preview-cell {
  padding-left: 4px;
  padding-right: 4px;
}

.editor-grid .preview-row[data-gap-x="17"] {
  margin-left: -4.25px;
  margin-right: -4.25px;
}

.editor-grid .preview-row[data-gap-x="17"] .preview-cell {
  padding-left: 4.25px;
  padding-right: 4.25px;
}

.editor-grid .preview-row[data-gap-x="18"] {
  margin-left: -4.5px;
  margin-right: -4.5px;
}

.editor-grid .preview-row[data-gap-x="18"] .preview-cell {
  padding-left: 4.5px;
  padding-right: 4.5px;
}

.editor-grid .preview-row[data-gap-x="19"] {
  margin-left: -4.75px;
  margin-right: -4.75px;
}

.editor-grid .preview-row[data-gap-x="19"] .preview-cell {
  padding-left: 4.75px;
  padding-right: 4.75px;
}

.editor-grid .preview-row[data-gap-x="20"] {
  margin-left: -5px;
  margin-right: -5px;
}

.editor-grid .preview-row[data-gap-x="20"] .preview-cell {
  padding-left: 5px;
  padding-right: 5px;
}

.editor-grid .preview-row[data-gap-x="21"] {
  margin-left: -5.25px;
  margin-right: -5.25px;
}

.editor-grid .preview-row[data-gap-x="21"] .preview-cell {
  padding-left: 5.25px;
  padding-right: 5.25px;
}

.editor-grid .preview-row[data-gap-x="22"] {
  margin-left: -5.5px;
  margin-right: -5.5px;
}

.editor-grid .preview-row[data-gap-x="22"] .preview-cell {
  padding-left: 5.5px;
  padding-right: 5.5px;
}

.editor-grid .preview-row[data-gap-x="23"] {
  margin-left: -5.75px;
  margin-right: -5.75px;
}

.editor-grid .preview-row[data-gap-x="23"] .preview-cell {
  padding-left: 5.75px;
  padding-right: 5.75px;
}

.editor-grid .preview-row[data-gap-x="24"] {
  margin-left: -6px;
  margin-right: -6px;
}

.editor-grid .preview-row[data-gap-x="24"] .preview-cell {
  padding-left: 6px;
  padding-right: 6px;
}

.editor-grid .preview-cell {
  overflow: hidden;
}

.editor-grid .preview-cell[data-size="1"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-1-24);
          flex: 0 0 var(--grid-cell-size-1-24);
}

.editor-grid .preview-cell[data-size="2"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-2-24);
          flex: 0 0 var(--grid-cell-size-2-24);
}

.editor-grid .preview-cell[data-size="3"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-3-24);
          flex: 0 0 var(--grid-cell-size-3-24);
}

.editor-grid .preview-cell[data-size="4"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-4-24);
          flex: 0 0 var(--grid-cell-size-4-24);
}

.editor-grid .preview-cell[data-size="5"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-5-24);
          flex: 0 0 var(--grid-cell-size-5-24);
}

.editor-grid .preview-cell[data-size="6"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-6-24);
          flex: 0 0 var(--grid-cell-size-6-24);
}

.editor-grid .preview-cell[data-size="7"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-7-24);
          flex: 0 0 var(--grid-cell-size-7-24);
}

.editor-grid .preview-cell[data-size="8"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-8-24);
          flex: 0 0 var(--grid-cell-size-8-24);
}

.editor-grid .preview-cell[data-size="9"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-9-24);
          flex: 0 0 var(--grid-cell-size-9-24);
}

.editor-grid .preview-cell[data-size="10"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-10-24);
          flex: 0 0 var(--grid-cell-size-10-24);
}

.editor-grid .preview-cell[data-size="11"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-11-24);
          flex: 0 0 var(--grid-cell-size-11-24);
}

.editor-grid .preview-cell[data-size="12"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-12-24);
          flex: 0 0 var(--grid-cell-size-12-24);
}

.editor-grid .preview-cell[data-size="13"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-13-24);
          flex: 0 0 var(--grid-cell-size-13-24);
}

.editor-grid .preview-cell[data-size="14"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-14-24);
          flex: 0 0 var(--grid-cell-size-14-24);
}

.editor-grid .preview-cell[data-size="15"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-15-24);
          flex: 0 0 var(--grid-cell-size-15-24);
}

.editor-grid .preview-cell[data-size="16"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-16-24);
          flex: 0 0 var(--grid-cell-size-16-24);
}

.editor-grid .preview-cell[data-size="17"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-17-24);
          flex: 0 0 var(--grid-cell-size-17-24);
}

.editor-grid .preview-cell[data-size="18"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-18-24);
          flex: 0 0 var(--grid-cell-size-18-24);
}

.editor-grid .preview-cell[data-size="19"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-19-24);
          flex: 0 0 var(--grid-cell-size-19-24);
}

.editor-grid .preview-cell[data-size="20"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-20-24);
          flex: 0 0 var(--grid-cell-size-20-24);
}

.editor-grid .preview-cell[data-size="21"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-21-24);
          flex: 0 0 var(--grid-cell-size-21-24);
}

.editor-grid .preview-cell[data-size="22"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-22-24);
          flex: 0 0 var(--grid-cell-size-22-24);
}

.editor-grid .preview-cell[data-size="23"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-23-24);
          flex: 0 0 var(--grid-cell-size-23-24);
}

.editor-grid .preview-cell[data-size="24"] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--grid-cell-size-24-24);
          flex: 0 0 var(--grid-cell-size-24-24);
}

.editor-grid .preview-cell[data-order="1"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-1);
      -ms-flex-order: var(--grid-cell-order-1);
          order: var(--grid-cell-order-1);
}

.editor-grid .preview-cell[data-order="2"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-2);
      -ms-flex-order: var(--grid-cell-order-2);
          order: var(--grid-cell-order-2);
}

.editor-grid .preview-cell[data-order="3"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-3);
      -ms-flex-order: var(--grid-cell-order-3);
          order: var(--grid-cell-order-3);
}

.editor-grid .preview-cell[data-order="4"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-4);
      -ms-flex-order: var(--grid-cell-order-4);
          order: var(--grid-cell-order-4);
}

.editor-grid .preview-cell[data-order="5"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-5);
      -ms-flex-order: var(--grid-cell-order-5);
          order: var(--grid-cell-order-5);
}

.editor-grid .preview-cell[data-order="6"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-6);
      -ms-flex-order: var(--grid-cell-order-6);
          order: var(--grid-cell-order-6);
}

.editor-grid .preview-cell[data-order="7"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-7);
      -ms-flex-order: var(--grid-cell-order-7);
          order: var(--grid-cell-order-7);
}

.editor-grid .preview-cell[data-order="8"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-8);
      -ms-flex-order: var(--grid-cell-order-8);
          order: var(--grid-cell-order-8);
}

.editor-grid .preview-cell[data-order="9"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-9);
      -ms-flex-order: var(--grid-cell-order-9);
          order: var(--grid-cell-order-9);
}

.editor-grid .preview-cell[data-order="10"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-10);
      -ms-flex-order: var(--grid-cell-order-10);
          order: var(--grid-cell-order-10);
}

.editor-grid .preview-cell[data-order="11"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-11);
      -ms-flex-order: var(--grid-cell-order-11);
          order: var(--grid-cell-order-11);
}

.editor-grid .preview-cell[data-order="12"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-12);
      -ms-flex-order: var(--grid-cell-order-12);
          order: var(--grid-cell-order-12);
}

.editor-grid .preview-cell[data-order="13"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-13);
      -ms-flex-order: var(--grid-cell-order-13);
          order: var(--grid-cell-order-13);
}

.editor-grid .preview-cell[data-order="14"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-14);
      -ms-flex-order: var(--grid-cell-order-14);
          order: var(--grid-cell-order-14);
}

.editor-grid .preview-cell[data-order="15"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-15);
      -ms-flex-order: var(--grid-cell-order-15);
          order: var(--grid-cell-order-15);
}

.editor-grid .preview-cell[data-order="16"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-16);
      -ms-flex-order: var(--grid-cell-order-16);
          order: var(--grid-cell-order-16);
}

.editor-grid .preview-cell[data-order="17"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-17);
      -ms-flex-order: var(--grid-cell-order-17);
          order: var(--grid-cell-order-17);
}

.editor-grid .preview-cell[data-order="18"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-18);
      -ms-flex-order: var(--grid-cell-order-18);
          order: var(--grid-cell-order-18);
}

.editor-grid .preview-cell[data-order="19"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-19);
      -ms-flex-order: var(--grid-cell-order-19);
          order: var(--grid-cell-order-19);
}

.editor-grid .preview-cell[data-order="20"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-20);
      -ms-flex-order: var(--grid-cell-order-20);
          order: var(--grid-cell-order-20);
}

.editor-grid .preview-cell[data-order="21"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-21);
      -ms-flex-order: var(--grid-cell-order-21);
          order: var(--grid-cell-order-21);
}

.editor-grid .preview-cell[data-order="22"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-22);
      -ms-flex-order: var(--grid-cell-order-22);
          order: var(--grid-cell-order-22);
}

.editor-grid .preview-cell[data-order="23"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-23);
      -ms-flex-order: var(--grid-cell-order-23);
          order: var(--grid-cell-order-23);
}

.editor-grid .preview-cell[data-order="24"] {
  -webkit-box-ordinal-group: var(--grid-cell-order-24);
      -ms-flex-order: var(--grid-cell-order-24);
          order: var(--grid-cell-order-24);
}

.editor-grid .preview-cell .preview-cell-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  height: 25px;
  width: 100%;
  color: #0958d9;
  border: 2px solid #91caff;
  overflow: hidden;
}

.editor-grid .preview.preview-variant .preview-row, .preview.preview-variant .preview-cell {
  border-color: #000;
  color: #000;
}

.editor-grid .placeholder-text {
  margin-bottom: 10px;
}

.editor-grid .placeholder-text div {
  height: 10px;
  background-color: #d7dde6;
  margin-bottom: 5px;
}

.editor-grid .desktop-preview .placeholder-text div:nth-child(1) {
  width: 150px;
}

.editor-grid .desktop-preview .placeholder-text div:nth-child(2) {
  width: 90px;
}

.editor-grid .desktop-preview .placeholder-text div:nth-child(3) {
  width: 80px;
}

.editor-grid .desktop-preview .placeholder-text div:nth-child(4) {
  width: 200px;
}

.editor-grid .tablet-preview .placeholder-text div:nth-child(1) {
  width: 130px;
}

.editor-grid .tablet-preview .placeholder-text div:nth-child(2) {
  width: 70px;
}

.editor-grid .tablet-preview .placeholder-text div:nth-child(3) {
  width: 60px;
}

.editor-grid .tablet-preview .placeholder-text div:nth-child(4) {
  width: 150px;
}

.editor-grid .mobile-preview .placeholder-text div:nth-child(1) {
  width: 70px;
}

.editor-grid .mobile-preview .placeholder-text div:nth-child(2) {
  width: 50px;
}

.editor-grid .mobile-preview .placeholder-text div:nth-child(3) {
  width: 30px;
}

.editor-grid .mobile-preview .placeholder-text div:nth-child(4) {
  width: 65px;
}

.editor-grid .placeholder-image {
  height: 70px;
  width: 100%;
  background-color: #d7dde6;
  margin-top: 10px;
  margin-bottom: 10px;
}

span[data-type="variable"] {
  background-color: #e3e3e3;
  padding: 0 4px;
  border-radius: 2px;
  font-style: italic;
}
