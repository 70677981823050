.editor-arinfo-training-more .editor * {
  margin: 0;
}

.editor-arinfo-training-more .editor-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  overflow-x: auto;
}

/* Textes */

.editor-arinfo-training-more .editor h3 {
  color: #315397 !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  font-family: 'Poppins', sans-serif;
}

.editor-arinfo-training-more .editor h3:not(:last-child) {
  margin-bottom: 20px !important;
}

.editor-arinfo-training-more .editor h3 * {
  color: #315397 !important;
}

.editor-arinfo-training-more .editor h3 b, .editor-arinfo-training-more .editor h3 strong {
  font-weight: 700;
}

.editor-arinfo-training-more .editor h3 em, .editor-arinfo-training-more .editor h3 i {
  font-style: normal;
}

.editor-arinfo-training-more .editor h4 {
  color: #6777B0 !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 10px !important;
}

.editor-arinfo-training-more .editor h4:not(:last-child) {
  margin-bottom: 10px !important;
}

.editor-arinfo-training-more .editor h4 * {
  color: #6777B0 !important;
}

.editor-arinfo-training-more .editor h4 b, .editor-arinfo-training-more .editor h4 strong {
  font-weight: 700;
}

.editor-arinfo-training-more .editor h4 em, .editor-arinfo-training-more .editor h4 i {
  font-style: normal;
}

.editor-arinfo-training-more .editor p {
  color: #282931;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  font-family: 'Nunito', sans-serif;
}

.editor-arinfo-training-more .editor p:not(:last-child) {
  margin-bottom: 20px !important;
}

.editor-arinfo-training-more .editor p b, .editor-arinfo-training-more .editor p strong {
  font-weight: 700;
}


.editor-arinfo-training-more .editor a {
  color: #6777B0 !important;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}

.editor-arinfo-training-more .editor a * {
  color: #6777B0 !important;
}

.editor-arinfo-training-more .editor a:hover {
  color: #6777B0;
  text-decoration: underline;
}

/* Citation */

.editor-arinfo-training-more .editor blockquote {
  background-color: #f4e55a66;
  color: #315397;
  border-radius: 0px 12px 12px 12px;
  padding: 20px;
  font-style: italic;
  overflow: hidden;
}

.editor-arinfo-training-more .editor blockquote:not(:last-child) {
  margin-bottom: 20px;
}

.editor-arinfo-training-more .editor blockquote *:not(a):not(h3):not(h4) {
  color: #315397 !important;
}

/* Listes */

.editor-arinfo-training-more .editor ul:not(:last-child), .editor-arinfo-training-more .editor ol:not(:last-child) {
  margin-bottom: 20px;
}

.editor-arinfo-training-more .editor ul {
  list-style-position: inside;
  list-style: disc;
}

.editor-arinfo-training-more .editor ol {
  list-style-position: inside;
  list-style: numeric;
}

.editor-arinfo-training-more .editor li {}

.editor-arinfo-training-more .editor li p {
  display: inline;
}

/* Déroulant */

.editor-arinfo-training-more .editor .node-details:not(:last-child) {
  margin-bottom: 20px !important;
}

.editor-arinfo-training-more .editor .details-container {
  background-color: #D7D9EB;
  border: none;
  border-radius: 0px 12px 12px 12px;
  overflow: hidden;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  gap: 10px;
  padding: 10px;
}

.editor-arinfo-training-more .editor .details-container .details-toggle {
  padding: 10px 10px 0 0;
}

.editor-arinfo-training-more .editor .details-container .details-toggle button {
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 18px;
  cursor: pointer;
  padding: 4px;
}

.editor-arinfo-training-more .editor .details-container .details-summary {
  padding: 10px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  border: 1px dashed #000;
  color: #315397;
}

.editor-arinfo-training-more .editor .details-container .details-summary::before {
  color: #8b8b8b !important;
}

.editor-arinfo-training-more .editor .details-container .details-content {
  margin: 10px 0 0 0;
  padding: 10px;
  border: 1px dashed #000 !important;
}

/* Grille */

.editor-arinfo-training-more .editor .grid:not(:last-child) {
  margin-bottom: 20px !important;
}

/* Image et vidéo */

.editor-arinfo-training-more .editor figure {
  margin: 0 auto;
  position: relative;
}

.editor-arinfo-training-more .editor figure:not(:last-child) {
  margin-bottom: 20px !important;
}

.editor-arinfo-training-more .editor .node-cloudinaryVideo:not(:last-child) {
  margin-bottom: 20px !important;
}

.editor-arinfo-training-more .editor figcaption {
  margin-top: 10px;
  text-align: center;
  font-style: italic;
  color: #6a6a94;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
}

.editor-arinfo-training-more .editor figure img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  border-radius: 0px 12px 12px 12px;
  overflow: hidden;
}

.editor-arinfo-training-more .editor figure[data-video] {
  width: 100%;
  max-width: 800px;
}

.editor-arinfo-training-more .editor figure[data-video] iframe {
  display: block;
  margin: 0 auto;
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  background-color: #000;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.editor-arinfo-training-more .editor figure[data-video] .ant-upload {
  margin-top: 10px;
}

/* Youtube */

.editor-arinfo-training-more .editor div[data-youtube-video]:not(:last-child) {
  margin-bottom: 20px !important;
}

.editor-arinfo-training-more .editor div[data-youtube-video] iframe {
  display: block;
  margin: 0 auto;
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 800px;
  height: auto;
  background-color: #000;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/* Vimeo */

.editor-arinfo-training-more .editor div[data-vimeo-video]:not(:last-child) {
  margin-bottom: 20px !important;
}

.editor-arinfo-training-more .editor div[data-vimeo-video] iframe {
  display: block;
  margin: 0 auto;
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 800px;
  height: auto;
  background-color: #000;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
