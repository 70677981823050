.tag-icon-fix span:last-child{
  display: none;
}

.ant-input-number-group-custom .ant-input-number-group-custom-input:first-child .ant-input-number-group-addon {
  border-right: none !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.custom-list-item-inter-intra {
  border: none !important;
}

.custom-list-item-inter-intra:not(:last-child) {
  margin-bottom: 24px;
}

.custom-inter-intra-stat {
  cursor: pointer;
  -webkit-transition: border 0.2s, background-color 0.2s;
  -o-transition: border 0.2s, background-color 0.2s;
  transition: border 0.2s, background-color 0.2s;
}

.custom-inter-intra-stat:hover {
  border-color: #1890ff;
}

.custom-inter-intra-stat.custom-inter-intra-stat-active {
  border-color: #1890ff;
  background-color: #e6f4ff;
}

.ant-input-number-group-custom .ant-input-number-group-custom-input:last-child  {}

.ant-spin-nested-loading > div > .ant-spin-large {
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
}

.inter-intra-collapse .ant-collapse-header, .inter-intra-collapse .ant-collapse-content-box {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-picker-cell-selected:not(.ant-picker-cell-custom-selected) .ant-picker-cell-custom:not(.ant-picker-cell-custom-selected) {
  color: #000 !important;
  background: #fff !important;
}

.ant-picker-cell-custom-selected {
  color: #fff !important;
  background: #1890ff !important;
}

.application-activity-list .ant-col {
  height: 100%;
}

.input-number-group .ant-input-number-group-addon {
  border-left: 0;
  border-radius: 0;
}

.date-schedule-list .ant-list-pagination {
  margin-top: 8px;
  margin-bottom: 8px;
}

.log-collapse .ant-collapse-content-box {
  padding: 0 !important;
}

.log-collapse.log-collapse-base .log-timeline {
  padding-top: 16px !important;
}

.log-collapse .log-collapse .ant-collapse-content-box {
  padding-left: 16px !important;
}

.log-item:last-child {
  padding-bottom: 0;
}

.inline-editable {
  margin: 0 !important;
  left: 0 !important;
  width: 100%;
}

.ant-upload-block {
  width: 100%;
}

.ant-upload-block .ant-upload {
  width: 100%;
}

.queuedItemDisabled {
  opacity: 0.4;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.queuedItemDisabled:hover {
  opacity: 1;
}

.aiAppSelection {
  -webkit-transition: border 0.2s;
  -o-transition: border 0.2s;
  transition: border 0.2s;
  cursor: pointer;
}

.aiAppSelection:hover {
  border-color: #1890ff;
}

#tinymce p {
  margin: 0 !important;
}

.ant-avatar-string {
  line-height: 30px !important;
}

.ant-picker {
  width: 100%;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.cpf-tabs.ant-tabs .ant-typography-edit-content {
  margin: 0 0 0 0;
  width: 100%;
}

.cpf-tabs .ant-tabs-nav-wrap {
  overflow: visible !important;
}

.cpf-card .ant-card-body {
  padding: 0;
}

.cpf-card .ant-list-header {
  padding: 8px 16px;
}

.cpf-card .ant-table-row:last-child .ant-table-cell {
  border-bottom: 0;
}

.cpf-modal-viewer .ant-tabs-nav,
.cpf-modal-viewer .ant-collapse {
  border-radius: 0 !important;
  border-top: 0;
  margin-bottom: 0 !important;
}

.cpf-modal-viewer .ant-tabs-nav-wrap {
  margin: 0 24px;
}

.cpf-update tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

.cpf-update tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.cpf-update .fixed-table .ant-table-thead {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  opacity: 1;
  z-index: 1;
}

.cpf-update .fixed-table .ant-table-summary {
  background-color: #fafafa;
}

.color-picker-disabled {
  cursor: not-allowed !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  opacity: 0.9 !important;
}

.color-picker-disabled span,
.color-picker-disabled div {
  pointer-events: none !important;
}

.ant-select-item-option-disabled .ant-select-item-option-content {
  color: rgba(0, 0, 0, 0.25) !important;
}


.ant-dropdown {
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
}

.vertical-group .ant-checkbox-group-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.ant-image-preview-mask {
  z-index: 1071;
}

.import-form .ant-upload {
  width: 100%;
}
.ant-table-row-danger {
  background-color: #ffa39e !important;
}

.ant-table-row-danger .ant-table-column-sort {
  background-color: #ffa39e !important;
}

.ant-table-row-danger td {
  background-color: #ffa39e !important;
  border-color: #f5222d !important;
}

.ant-table-row-danger td::after {
  border-color: #f5222d !important;
}

.ant-table-row-danger:hover td {
  background-color: #ffa39e !important;
}

.ant-table-row-success {
  background-color: #b7eb8f !important;
}

.ant-table-row-success .ant-table-column-sort {
  background-color: #b7eb8f !important;
}

.ant-table-row-success td {
  background-color: #b7eb8f !important;
  border-color: #52c41a !important;
}

.ant-table-row-success td::after {
  border-color: #52c41a !important;
}

.ant-table-row-success:hover td {
  background-color: #b7eb8f !important;
}

.ant-table-row-warning {
  background-color: #ffe58f !important;
}

.ant-table-row-warning .ant-table-column-sort {
  background-color: #ffe58f !important;
}

.ant-table-row-warning td {
  background-color: #ffe58f !important;
  border-color: #f1ce5e !important;
}

.ant-table-row-warning:hover td {
  background-color: #ffe58f !important;
}

.ant-table-row-warning td::after {
  border-color: #f1ce5e !important;
}

.ant-table-row-danger-border {
  background-color: #ff4d4f !important;
}

.ant-table-row-danger-border td {
  background-color: #ff4d4f !important;
}

.drag-collapse .ant-collapse-content-box {
  padding: 0;
}

.drag-collapse .ant-collapse-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.drag-collapse .ant-collapse-header-text {
  width: auto !important;
  margin-right: auto !important;
}

.drag-collapse .ant-input-group.ant-input-group-compact::before {
  display: none;
}

.exercises-collapse .ant-collapse-content-box {
  padding: 0;
}

.single-collapse .ant-collapse-content-box {
  padding: 0;
}

.single-collapse .ant-collapse-content {
  border-top: none;
}

.single-collapse .ant-collapse-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.single-collapse .ant-collapse-header-text {
  width: auto !important;
}

.resources-collapse .ant-collapse-content-box {
  padding: 0;
}

.module-collapse .ant-collapse-header {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.module-edit .ant-collapse-header {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.module-edit .ant-collapse-header-text {
  margin-right: 5px;
  width: 100%;
}

.module-edit .ant-collapse-extra {
  margin-left: 0 !important;
}

.module-edit .ant-typography-edit-content {
  margin: 0 0 0 8px;
  width: 100%;
}

.arinfo-faq-collapse .ant-collapse-header-text {
  margin-right: 5px;
  width: 100%;
}

.arinfo-faq-collapse .ant-typography-edit-content {
  margin: 0 0 0 8px;
  width: 100%;
}

.module-edit .course-list-item div>.ant-space:first-child {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.module-edit .course-list-item div>.ant-space:first-child .ant-space-item:last-child {
  margin-right: 5px;
  width: 100%;
}

.module-edit .course-list-item div>.ant-space:first-child .ant-typography-edit-content {
  width: 100%;
}

.program-collapse .ant-collapse-header {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.program-edit .ant-collapse-header {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.program-edit .ant-collapse-header-text {
  margin-right: 5px;
  width: 100%;
}

.program-edit .ant-collapse-extra {
  margin-left: 0 !important;
}

.program-edit .ant-typography-edit-content {
  margin: 0 0 0 8px;
  width: 100%;
}

.program-edit .course-list-item div>.ant-space:first-child {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.program-edit .course-list-item div>.ant-space:first-child .ant-space-item:last-child {
  margin-right: 5px;
  width: 100%;
}

.program-edit .course-list-item div>.ant-space:first-child .ant-typography-edit-content {
  width: 100%;
}

.student-collapse .ant-collapse-header {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.student-create .ant-collapse-header {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.student-create .ant-collapse-header-text {
  margin-right: 5px;
  width: 100%;
}

.student-create .ant-collapse-extra {
  margin-left: 0 !important;
}

.student-create .ant-typography-edit-content {
  margin: 0 0 0 8px;
  width: 100%;
}

.student-create .course-list-item div>.ant-space:first-child {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.student-create .course-list-item div>.ant-space:first-child .ant-space-item:last-child {
  margin-right: 5px;
  width: 100%;
}

.student-create .course-list-item div>.ant-space:first-child .ant-typography-edit-content {
  width: 100%;
}

.fc a {
  color: #000;
}

.fc table:not([cellpadding]) td,
.fc table:not([cellpadding]) th {
  padding: 0;
}

.fc-daygrid-day:hover {
  background-color: #eeeeee;
  cursor: pointer;
}

.paddingless_collapse .ant-collapse-content-box {
  padding: 0;
}

/* POSIAO */

.archives-search-container{
  background-color: rgb(250, 250, 250);
  border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  padding: 16px;
}

/* Preview */

.header-container {
  background-color: #315397;
  border-top-left-radius: 20px;
  width: 100;
  padding: 20px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header-title,
.clock-count {
  color: #F5F5FB;
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 800;
  line-height: 55px;
  margin: 0;
}

.clock-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.clock-count {
  margin-left: 5px;
}

.home-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: auto;
  border-radius: 20px;
  border-top-right-radius: 0;
  background-color: #F5F5FB;
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
          filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.home-content {
  padding: 30px;
  border: 2px solid #315397;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.preview-instructions {
  color: #315397;
  border: 1px solid #315397;
  background-color: #D7D9EB;
  padding: 10px;
  border-radius: 5px;
  font-style: italic;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 30px;
}

.next-button {
  font-family: 'Nunito', sans-serif !important;
  background-color: #315397;
  border: none;
  border-radius: 500px;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  display: block;
  margin: 20px auto 0 auto;
  cursor: not-allowed;
  -webkit-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.preview-container {
  font-weight: 500;
  font-size: 16px !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-family: 'Nunito', sans-serif;
  margin: 24px 0;
}

.illustrated-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

/* Box into preview */

.column {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
}

.labels-box {
  width: 75%;
  margin: 0 auto;
}

.column,
.labels-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  border: 1px solid #D7D9EB;
  overflow: hidden;
}

.labels-box-title {
  background-color: #D7D9EB;
  width: 100%;
  padding: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.labels-box-drop {
  min-height: 70px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  padding: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.columns-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  gap: 20px;
  margin-bottom: 20px;
}

.column-labels {
  width: 100%;
  min-height: 120px;
  height: 100%;
  padding: 15px 15px 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/* Labels into previews */
.item {
  border: 1px dashed #D7D9EB;
  border-radius: 5px;
  padding: 8px;
  background-color: white;
  cursor: move;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.item:hover {
  background-color: #F5F5FB;
}

/* Card in ordering preview */
.card {
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 5px;
  padding: 8px;
  margin: 10px 0;
  cursor: move;
}

/* Checkbox into previews */
.preview-question {
  font-size: 24px;
  font-weight: 700;
}

.checkbox-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkbox-illustrated-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 200px;
}

.illustrated-image-hover {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden
}

.illustrated-image-hover::after {
  content: url('./assets/posiao/zoom.svg');
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
  cursor: pointer
}

.illustrated-image-hover:hover::after {
  opacity: 1;
}

.checkbox-label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  color: #324650;
  font-size: 16px;
}

.checkbox-label .checkbox-input {
  display: none;
}

.checkbox-label .checkbox-input:checked+.checkbox-box {
  background: #2e5596;
  border-color: #2e5596;
}

.checkbox-label .checkbox-input:checked+.checkbox-box:after {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.checkbox-label .checkbox-box {
  background-color: white;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #e6e1e5;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-transition: background 0.2s border-color 0.2s;
  -o-transition: background 0.2s border-color 0.2s;
  transition: background 0.2s border-color 0.2s;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkbox-text {
  margin: 0px 10px;
  font-weight: 500;
  font-size: 16px;
  padding: 0;
}

.checkbox-label .checkbox-box:hover {
  background: #e6e1e5;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.checkbox-label .checkbox-box:after {
  content: "\2714";
  color: #fff;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.checkbox-label-img {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-top: 1px solid #dbdbdb;
  width: 100%;
}

.question-list-item .ant-list-item-meta {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.question-list-item .ant-list-item-meta-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0;
}

.question-list-item .ant-list-item-meta-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.question-list-item .ant-list-item-meta-description {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.question-item-tags {
  margin-top: auto;
}

/* Completion.js */
.completion-textarea:hover,
.label:hover,
.completion-textarea:focus {
  border: 1px solid hsl(207, 100%, 62.5%) !important;
}

.completion-textarea:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

/* Général */
.lines {
  z-index: 5 !important;
}

.posiao-meta-card .ant-card-cover{
  height: 330px; 
}

.posiao-meta-card-img{
  max-width: 100%;
  width: auto;
  height: 330px;
  display: block;
  margin: 0 auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.posiao-meta-card-placeholder{
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  -o-object-fit: cover;
     object-fit: cover;
}

.posiao-meta-card-loading{
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}


/* Connect.js */
.square,
.preview-square {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 2px;
  height: 15px;
  width: 15px;
  min-height: 15px;
  min-width: 15px;
  cursor: pointer;
  margin: 0 auto;
}

.preview-square:hover,
.preview_selected,
.preview_linked_selected {
  border: 1px solid #315397;
  background-color: #315397;
}

.preview_linked {
  border: 1px solid #315397;
}

.square:hover,
.selected,
.linked_selected {
  border: 1px solid #1890ff;
  background-color: #1890ff;
}

.linked {
  border: 1px solid #1890ff;
}

.square_disabled, .square_disabled:hover{
  border: 1px solid #d9d9d9;
  background-color:#f5f5f5;
}

/* Legend preview */

.legend-preview-container .lists-box-main {
  background-color: white;
  border: 1px solid #315397;
  border-radius: 5px;
  min-height: 60px;
  width: 100%;
  overflow: hidden;
}


.legend-preview-container .lists-box-main:not(:last-child) {
  margin-bottom: 24px;
}

.legend-preview-container .legend-img-container {
  position: relative;
  width: 450px;
  height: 450px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgb(231, 231, 231);
}

.legend-preview-container .legend-img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

/* Legend.js */

.legend-container .legend-lists {
  top: 0px;
  width: 40%;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute
}

.legend-container .lists-box-main {
  border: 1px solid rgb(217, 217, 217);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
}


.legend-container .lists-box-main:not(:last-child),
.listsbox-container:not(:last-child) {
  margin-bottom: 24px;
}

.legend-container .listsbox-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 40px;
}

.legend-container .listsbox-margin {
  margin: 8px;
}

.legend-container .legend-img-container {
  position: relative;
  width: 450px;
  height: 450px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgb(231, 231, 231);
}

.legend-container .legend-img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.legend-label-container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 12px;
  background-color: #fafafa;
}

.legend-label-item, .organize-label, .organize-list-title, .archives-input{
  margin-bottom: 0;
}

/* Organize.js */

.organize-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid rgb(217, 217, 217);
  height: 100%;
}

.add-column {
  cursor: pointer;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #F9FCFE;
  height: 100%;
  width: 100%;
}

.organizelist-item {
  position: relative;
  overflow: hidden;
}

.organizelist-item .ant-form-item-control-input {
  min-height: 100%;
}

.organizelist-item .ant-form-item-control-input-content {
  height: 100%;
}

.organizelist-item-overlay {
  background: rgb(0 0 0 / 70%);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* Label.js */

.label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 5px;
}

/* Labels box */

.labels-box-container {
  padding: 8px;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 5px;
  margin-top: 20px;
}

/* Liste des types */

.types-list-item:hover {
  cursor: pointer;
  background-color: lightgray;
}

.types-list-item-disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

/* Ordering */

.ordering-tag-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* Illustrated.js */

.illu-img-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 150px;
  width: 150px;
  background-color: rgb(231, 231, 231);
}

.dragger-button-illustrated {
  height: 150px !important;
  width: 150px !important;
}

/* TrueFalse.js */

.true-button,
.false-button {
  font-family: 'Nunito';
  font-size: 16px;
  text-transform: uppercase;
  background: none;
  border-radius: 500px;
  padding: 10px 20px;
  width: 100px;
  cursor: pointer;
  position: relative;
  text-align: center;
  border: none;
}

.true-button.selected::after,
.false-button.selected::after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 500px;
}

.true-button.selected::after {
  border: 4px solid #4DDD98;
}

.false-button.selected::after {
  border: 4px solid #E73333;
}

.true-button,
.true-button.selected {
  border: none;
  margin-right: 20px;
  color: white;
  background-color: #4DDD98;
}

.false-button,
.false-button.selected {
  border: none;
  color: white;
  background-color: #E73333;
}

.true-button:hover {
  background-color: rgba(77, 221, 152, .6);
}

.false-button:hover {
  background-color: rgba(231, 51, 51, .6);
}

.selected-text {}

.selected-text:hover {
  background-color: lightgray;
}

body.selectionTooLong .completion-editor *::-moz-selection {
  background-color: #ff4d4f !important;
}

body.selectionTooLong .completion-editor *::selection {
  background-color: #ff4d4f !important;
}

.completion-editor {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  min-height: 200;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.completion-editor:hover {
  border: 1px solid #40a9ff;
}

.completion-editor:focus {
  border: 1px solid #40a9ff;
  -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
          box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  outline: 0;
}

.preview-question-image {
  max-width: 100%;
  max-height: 500px;
  display: block;
  margin: 0 auto 30px
}

.editable-fix {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.editable-fix .ant-typography-edit-content {
  margin: 0 0 0 8px;
}

/* Avatar */

.avatar-card{
  width: 300px;
  overflow: hidden;
}

.avatar-card .ant-card-cover{
  height: 300px;
}

.avatar-card-img{
  max-width: 100%;
  width: auto;
  height: 300px;
  display: block;
  margin: 0 auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.avatar-card-placeholder{
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  -o-object-fit: cover;
     object-fit: cover;
}

.avatar-card-loading{
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* Arinfo Posts preview */

.post-title{
  font-size: 40px;
  line-height: 40px;
  font-weight: 800;
  color: #282931;
  font-family: 'Nunito';
  text-align: center;
  margin-bottom: 20px;
}

.post-date{
  text-align: center;
  margin-bottom: 60px;
}

.post-content, .post-date{
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  color: #282931;
  font-family: 'Nunito';
}

.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4{
  color : #282931;
}

.post-content h2{
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
}

.post-content h2:not(:last-child){
  margin-bottom: 15px;
}

.post-content h3{
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}

.post-content h3:not(:last-child){
  margin-bottom: 10px;
}

.post-content a{
  color: #315397;
  text-decoration: underline;
}

.post-content a.hidden-link{
  color: #282931;
  text-decoration: none;
  cursor: text;
}

.post-content td{
  padding: 5px !important;
  vertical-align: middle;
}

.post-content b,
.post-content strong{
  font-weight: bold;
}

.post-content p{
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color : #282931;
}

.post-content img{
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 565px;
}

.post-content p:not(:last-child),
.post-content img:not(:last-child),
.post-content ol:not(:last-child),
.post-content ul:not(:last-child){
  margin-bottom: 24px;
}

.post-content ol{
  list-style: decimal;
}

.post-content ul{
  list-style: disc;
}

.post-content ul,
.post-content ol {
  list-style-position: inside;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color : #282931;
  padding-left: 0;
}

.post-content ul ul,
.post-content ol ol{
  padding-left: 40px;
}

.post-content iframe{
  margin-left:50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%); 
}

.imaginelab-workshop-age-range {
  width: 100%;
}

.imaginelab-workshop-age-range .ant-space-item{
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.imaginelab-workshop-calendar{
  --fc-border-color: #d9d9d9;
  --fc-button-bg-color: #fff;
  --fc-button-border-color: #d9d9d9;
  --fc-button-hover-bg-color: #fff;
  --fc-button-hover-border-color: #40a9ff;;
  --fc-button-active-bg-color: #fff;
  --fc-button-active-border-color: #096dd9;
  --fc-today-bg-color: rgba(24,144,255,0.1);
  --fc-bg-event-color: #d9d9d9;
  --fc-bg-event-opacity: 0.5;
}

.imaginelab-workshop-calendar .fc-button{
  border-radius: 2px;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color:rgba(0, 0, 0, 0.85);
}

.imaginelab-workshop-calendar .fc-button:disabled{
  cursor: not-allowed;
  color: rgba(0,0,0,0.25);
  --fc-button-bg-color: #f5f5f5;
  --fc-button-border-color: #d9d9d9;
}

.imaginelab-workshop-calendar .fc-button:not(:disabled):hover{
  color:#40a9ff;
}

.imaginelab-workshop-calendar .fc-button:not(:disabled):active{
  color:#096dd9 !important;
}

.imaginelab-workshop-calendar .fc-col-header-cell{
  text-transform: capitalize;
}

.imaginelab-workshop-calendar .fc-col-header-cell:not(.fc-day-today) {
  font-weight: 400;
}

.imaginelab-workshop-calendar .fc-daygrid-day:not(.fc-day-today):hover{
  background-color: rgba(235,235,235, 0.3);
}

.imaginelab-document-upload .ant-upload {
  width: 100%;
}

.imaginelab-workshops-image-card{
  overflow: hidden;
}

.imaginelab-workshops-image-card .ant-card-cover{
  height: 300px;
}

.imaginelab-workshops-image-card-img{
  max-width: 100%;
  width: auto;
  height: 300px;
  display: block;
  margin: 0 auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.imaginelab-workshops-image-card-placeholder{
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  -o-object-fit: cover;
     object-fit: cover;
}

.imaginelab-workshops-datepicker .ant-picker-time-panel-column::after{
 height: auto;
}

.imaginelab-workshop-calendar-dots{
  margin-right: 4px;
  border-radius: 4px
}
.arinfo-faq-collapse{
  margin-bottom: 20px;
  padding: 0px;
}

.arinfo-faq-collapse > .ant-collapse-content > .ant-collapse-content-box{
  padding: 0;
}

.arinfo-faq-nested-collapse .ant-collapse-item:not(:last-child){
  border-bottom: 1px solid #d9d9d9;
}

.arinfo-faq-edit-collapse .ant-collapse-item{
  border-top: 1px solid #d9d9d9;
}

.arinfo-faq-nested-collapse .ant-collapse-content-box{
  padding-left: 40px;
}

.arinfo-faq-collapse .ant-collapse-header{
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
  align-items: center !important;
}

.arinfo-faq-add-section-button{
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.arinfo-faq-add-question{
  padding: 12px 16px;
  border-top: 1px solid #d9d9d9;
}

.arinfo-faq-question{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.arinfo-faq-question .ant-collapse-header{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 12px 16px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.arinfo-faq-question .ant-collapse-expand-icon{
  margin-right: 10px;
}

.arinfo-faq-question .ant-collapse-extra{
  margin-left: auto;
}

.arinfo-faq-question .ant-collapse-content-box{
  padding: 0;
}

.arinfo-faq-answer-display p{
  margin: 0px;
  padding: 16px 16px 16px 40px;
}

.evaluations span:not([class]) {
  width: 100%;
}

.evaluations .evaluation-level .ant-radio-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.evaluations .ant-checkbox, .evaluations .ant-radio {
  top: -0.45em;
}
.table-expanded-paddingless .ant-table-expanded-row > .ant-table-cell {
  padding: 0 !important;
}

.ant-btn-warning {
  color: #fff;
  border-color: #faad14;
  background: #faad14;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ai-list-compact {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.ai-list-compact .ant-list {
  height: 100% !important;
}

.ai-list-compact .ant-list-header {
  background-color: #fafafa !important;
}

.ai-list-compact div:first-child .ant-list-bordered {
  border-bottom: none !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ai-list-compact div:not(:first-child) .ant-list-bordered {
  border-top: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ai-list-compact .ant-list-pagination {
  margin: 8px 12px !important;
}

.ai-list-compact .ant-list-pagination:empty {
  margin: 0 !important;
}

@media screen and (min-width: 1200px) {
  .ai-list-compact {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
  }

  .ai-list-compact div:first-child .ant-list-bordered {
    border-bottom: 1px solid #d9d9d9 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 1px solid #d9d9d9 !important;
    border-bottom-right-radius: 0 !important;
  }
  
  .ai-list-compact div:not(:first-child) .ant-list-bordered {
    border-top: 1px solid #d9d9d9 !important;
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 1px solid #d9d9d9 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.report-card * {
  border-collapse: collapse !important;
}

.report-card-formation-uninterrupted .ant-descriptions-view,
.report-card-student-uninterrupted .ant-descriptions-view,
.report-card-former-uninterrupted .ant-descriptions-view,
.report-card-reports-alternation .ant-descriptions-view,
.report-card-company .ant-descriptions-view{
  border: none;
  border-top: 1px solid #f0f0f0;
}

.report-card-formation-alternation .ant-descriptions-view,
.report-card-student-alternation .ant-descriptions-view,
.report-card-reports-uninterrupted .ant-descriptions-view,
.report-card-former-alternation .ant-descriptions-view{
  border: none;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.report-card-formation-alternation .ant-descriptions-header,
.report-card-formation-uninterrupted .ant-descriptions-header,
.report-card-company .ant-descriptions-header,
.report-card-reports-alternation .ant-descriptions-header,
.report-card-reports-uninterrupted .ant-descriptions-header,
.report-card-student-uninterrupted .ant-descriptions-header,
.report-card-student-alternation .ant-descriptions-header,
.report-card-former-uninterrupted .ant-descriptions-header,
.report-card-former-alternation .ant-descriptions-header{
  margin: 0;
}

.report-card-formation-alternation .ant-descriptions-title,
.report-card-formation-uninterrupted .ant-descriptions-title,
.report-card-company .ant-descriptions-title,
.report-card-reports-alternation .ant-descriptions-title,
.report-card-reports-uninterrupted .ant-descriptions-title,
.report-card-student-alternation .ant-descriptions-title,
.report-card-student-uninterrupted .ant-descriptions-title,
.report-card-former-alternation .ant-descriptions-title,
.report-card-former-uninterrupted .ant-descriptions-title{
  padding: 8px;
}

.reports-search-container, .activities-search-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #FAFAFA;
  padding: 16px;
  border: 1px solid #F0F0F0;
  border-radius: 2px;
}

.report-card-description-title{
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  font-size: 16px;
  padding: 8px;
  display: 'block';
}

.formation-reports-steps .ant-steps-item-description {
  max-width: 200px !important;
}

.formation-reports-steps .ant-steps-item-error .ant-steps-item-icon{
  background-color: #ff4d4f;
}

.formation-reports-steps .ant-steps-item-error .ant-steps-icon{
  color: #fff;
}

.radio-danger .ant-radio .ant-radio-inner{
  border: 1px solid red;
}

.monthly-reports-archived-table .ant-table-expanded-row .ant-table-cell{
  padding: 0 !important;
}

.reports-search-input {
  margin-bottom: 0;
}

.monthly-reports-actions .ant-space-item{
  width: 100%;
}

.reports-description .ant-descriptions-item-content{
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

span[data-type="variable"] {
  background: lightgray;
}

.api-ai-status-spin .ant-spin-dot {
  position: relative !important;
  left: 0 !important;
}

.module-preview-modal-collapse .ant-collapse-header{
  padding-block: 8px !important;
}

.module-preview-modal-collapse .ant-collapse-content-box{
  padding-block: 0 !important;
}

.monthly-reports-table .ant-table-expanded-row .ant-table-cell {
  padding: 0 !important
}
