.login-page {
  height: 100vh;
  background-color: #f0f2f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.login-page .form {
  margin: 0 !important;
  width: 100%;
  max-width: 500px;
}

.login-page .logo-container {
  height: 100px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 20px;
}

.login-page .logo {
  height: 100%;
  max-width: 100%;
}
